import React, { Component, Fragment } from 'react'
import {View } from 'react-native-web'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
//
import Colors from "../../commons/Colors";
//
import { setSnackbar, setTimer} from '../../actions/appActions'
import { toMoney } from '../../handlers/money';
import { GetHandler } from '../../handlers';
import SearchList from './searchList';
import { withRouter, WithRouterProps } from 'react-router-dom'


class Search extends Component {
  state = {
    profile: undefined,
    doctorData: undefined,
    showOrderpanel: false,
    orderBy: [
      { column: "pontuacao.desc", label: "Avaliação", checked: false },
      {
        column: "totalConsultasAtendidas.desc",
        label: "Número de consultas",
        checked: false,
      },
      { column: "precoConsulta.desc", label: "Maior Preço", checked: false },
      { column: "precoConsulta.asc", label: "Menor Preço", checked: false },
      { column: "pontuacao.desc", label: "Tempo de trabalho", checked: false },
    ],
    results: [],
    pageOptions: undefined,
    refreshing: false,
  };

   getData = async (pageOptions = undefined, orderBy = []) => {
    try {
      this.setState({...this.state, refreshing: true});
      let session = JSON.parse(localStorage.getItem('user_info'));
      let filter = this.props?.filter ?? this.props.location.state;
      //
      filter.nomeFormacao = filter.nomeFormacao.includes("todos") ? [""] : filter.nomeFormacao;
      filter.especialidade = filter.especialidade == "todos" ? "" : filter.especialidade;

      filter.valorConsultaMax = toMoney(filter.valorConsultaMax, true).replace(',', '.');
      filter.valorConsulta = toMoney(filter.valorConsulta, true).replace(',', '.');
      filter.especialidade = filter.especialidade ?? "";
      filter.perPage = 5;
      filter.favoritoPacienteId = this.props.profile?.data?.id ?? session?.id ?? "";
      let order = orderBy
        .filter((item) => item.checked)
        .map((item) => item.column)
        .join(",");
      filter.orderBy = order;

      filter.nomeFormacao = filter.nomeFormacao ?? [];

      if (pageOptions) {
        filter = { ...filter, page: parseInt(pageOptions.page) + 1 };
      }
      let request = await GetHandler(`doutores`, filter, this.props.navigation);
      if (
        request &&
        Array.isArray(request?.data?.results) &&
        request?.data?.results?.length >= 0
      ) {
        let results = request?.data?.results ?? [];
        if (pageOptions) {
          results = this.state.results.concat(results);
        }

        this.setState({
          results,
          pageOptions: {
            page: request?.data?.page,
            perPage: request?.data?.perPage,
            totalPages: request?.data?.totalPages,
          },
          refreshing: false
        });
      }else if(request){
        this.setState({...this.state, refreshing: false});
      }
    } catch (e) {
      console.error(e);
    }
    //this.setState({...this.state, refreshing: false});
  }

  async componentDidMount() {
    //console.log('FILTROS:: ', this.props.filter)
    await this.getData();
  }
  
    render() {
        return (
            <Fragment>
             <View style={{flexDirection: 'row', justifyContent: 'center'}}>
              <View style={{flexBasis: window.innerWidth >= 680 ? '80%' : '100%'}}>
                <SearchList filter={this.props?.location?.state} location={this.props.location.state} data={this.state.results} getData={() => {this.getData()}}  />
              </View>
             </View>
            </Fragment>
        )
    }
}
const mapStateToProps = store => ({
    session: store.authReducer.data,
});
const mapDispatchToProps = dispatch =>
    bindActionCreators({ setSnackbar, setTimer }, dispatch);

    
export default withRouter(Search);

const styles = {
  container: {
    paddingTop: 30,
    borderRadius: 0,
    backgroundColor: Colors.primary,
    minHeight: "100%",
  },
  secView: {
    flex: 1,
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    paddingRight: 15,
    maxHeight: 65,
    paddingBottom: 15,
    bottom: 0,
    backgroundColor: Colors.secondary,
    width: "100%",
  },
  logoContainer: {
    alignItems: "center",
    justifyContent: "center",
    padding: 30,
  },
  logo: {
    height: 150,
    width: 150,
    resizeMode: "contain",
  },
  inputDefaultStyle: {
    height: 40,
  },
};
