import React, { Component, Fragment, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
//
import Colors from "../../commons/Colors";
//

import { setSnackbar, setTimer, setProfileDialog } from "../../actions/appActions";
import { setAuth } from "../../actions/authAction";
import { Avatar, Button, Card, CardActions, CardContent, FormControl, Icon, InputLabel, List, ListItem, ListItemText, MenuItem, Select, TablePagination, TextField } from "@material-ui/core";
import { ActivityIndicator, ScrollView, StyleSheet, TouchableOpacity, View } from "react-native";
import { CheckCircleOutline, Image, InfoOutlined } from "@material-ui/icons";
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';

import { BRANDING } from "../../../branding";
import { GetHandler, PostHandler } from "../../handlers";
import LCardGrid from '../../components/List/cardgrid'
import moment from "moment";

const localizedTextsMap = {
    MuiTablePagination: {
        labelDisplayedRows: ({ from, to, count }) =>
            `${from} - ${to} de ${count}`,
    },
    columnMenuUnsort: "Não classificado",
    columnMenuSortAsc: "Classificar por ordem crescente",
    columnMenuSortDesc: "Classificar por ordem decrescente",
    columnMenuFilter: "Filtro",
    columnMenuHideColumn: "Ocultar",
    columnMenuShowColumns: "Mostrar colunas",
    columnManage: 'Gerenciar',
    noRow: 'Não há registros'
};

export function UserProfileInfo(props) {

    const [nome, setNome] = React.useState("");
    const [urlFoto, setUrlFoto] = React.useState("");
    const [sobrenome, setSobrenome] = React.useState("");
    const [telefone, setTelefone] = React.useState("");
    const [datanascimento, setDatanascimento] = React.useState(moment().format('yyyy-MM-DD'));
    const [cpf, setCPF] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [requiredDocs, setRequiredDocs] = React.useState([]);
    const [docs, setDocs] = React.useState([]);
    const [docStatus, setDocStatus] = React.useState([]);
    const [loading, setLoading] = React.useState(true);

    const [codigoConfirmacao, setCodigoConfirmacao] = React.useState('');
    const [enviaDepCodConfirmacao, setEnviaDepCodConfirmacao] = React.useState(true);


    const fileContainer = useRef();

    const onLoad = async () => {
        const auth = JSON.parse(localStorage.getItem('user_info'));
        console.log(auth);
        let request = await GetHandler(`pacientes/${auth?.id}`, {
            perfilId: BRANDING?.perfilId,
        });

        const data = request?.data?.data;
        if (data?.id != undefined) {
            setUrlFoto(data?.urlFoto)
            setNome(data?.nome)
            setSobrenome(data?.sobrenome);
            setTelefone(data?.celular);
            setEmail(data?.email);
            setCPF(data?.cpf);
            setDocStatus(data?.docVerificado);
        } else {
            setUrlFoto(auth?.urlFoto)
            setNome(auth?.nome)
            setSobrenome(auth?.sobrenome);
            setTelefone(auth?.celular);
            setEmail(auth?.email);
            setCPF(auth?.cpf);
        }

        if ([0, 3].includes(data?.docVerificado?.status)) {
            setLoading(true)
            await GetHandler(`configuracoes/documentosExigidos`, {
                perfilId: BRANDING?.perfilId,
                tipoDoc: 2,
                exibeCadastro: true
            }).then(res => {
                setRequiredDocs(res?.data?.data ?? []);
                setLoading(false)
            }).catch(e => {
                setLoading(false);
            });
        } else {
            setLoading(false);
        }

    }
    if (requiredDocs.length == 0 && docStatus?.status == 0) {
        onLoad();
    }

    const onSave = async () => {
        setLoading(true);
        const auth = JSON.parse(localStorage.getItem("user_info"));
        if (props?.onSave != undefined) {
            props.onSave();
        }

        let user = JSON.parse(localStorage.getItem("user_info"));
        user.nome = nome;
        user.sobrenome = sobrenome;
        user.email = email;
        user.celular = telefone;
        user.cpf = cpf;
        localStorage.setItem("user_info", JSON.stringify(user));
        props.setAuth(user);

        let data = {
            nome: nome,
            sobrenome: sobrenome,
            cpf: cpf.replace(/\D/g, ""),
            email: email,
            celular: telefone,
            perfilId: auth?.perfilId ?? auth?.perfilUsuarioId,
            pacienteDoc: [],
            enviaDepCodConfirmacao,
        };

        if (enviaDepCodConfirmacao == false) {
            data.codigoConfirmacao = codigoConfirmacao;
        }

        docs.map((doc) => {
            data.pacienteDoc.push({
                id: doc?.id,
                arquivo: doc?.file
            })
        });

        let change = await PostHandler(
            `pacientes/${user?.id}/atualizar`,
            data,
            props.navigation
        );
        if (change?.data?.success == true) {
            let message = change?.data?.message ?? "Enviado com Sucesso!";
            message += enviaDepCodConfirmacao ? ' via ' : '';
            let from = [];

            if (change?.data?.data?.sms == true) {
                from.push('SMS');
            }

            if (change?.data?.data?.email == true) {
                from.push('E-Mail');
            }

            if (change?.data?.data?.whatsapp == true) {
                from.push('WhatsApp');
            }

            alert(message + from.join(', '));
            setEnviaDepCodConfirmacao(false);

            if (codigoConfirmacao?.length > 0) {
                window.location.href = "/myprofile";
            }
        } else {
            alert(change?.data?.message ?? "Problema ao cadastrar!");
        }
    };

    useEffect(() => {
        onLoad();
    }, [])

    const styles = {
        listItem: {
            borderBottomColor: "grey",
            borderBottomWidth: 1,
            marginHorizontal: 15,
        },
    };

    if (enviaDepCodConfirmacao == false) {
        return (
            <div>
                <div style={{ marginTop: 30, backgroundColor: Colors.white, maxWidth: 950, padding: 10, borderRadius: 10 }}>
                    <ScrollView style={{ innerHeight: 170 }}>
                        <div>

                            <View
                                style={{
                                    marginTop: 10,
                                    paddingBottom: 25,
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "center"
                                }}
                            >
                                <InputLabel style={{ marginBottom: 15 }}>Insira o código de confirmação</InputLabel>
                                <TextField
                                    size="small"
                                    style={{ width: window.innerWidth >= 680 ? 180 : "100%" }}
                                    label="Código"
                                    variant="outlined"
                                    value={codigoConfirmacao}
                                    onChange={(e) => {
                                        setCodigoConfirmacao(e.target.value);
                                    }}
                                />
                            </View>
                        </div>
                    </ScrollView>
                </div>
                <div style={{ marginTop: 20, display: 'flex', flexDirection: 'row', justifyContent: 'center' }} >
                    <Button variant={"contained"} color={"primary"} onClick={onSave}>
                        Enviar
                    </Button>
                </div>
            </div>
        )
    }

    return (
        <div>
            <div style={{ backgroundColor: Colors.white, maxWidth: 950, padding: 10, borderRadius: 10 }}>
                {(cpf ?? "").length <= 10 && loading == false ? (
                    <div
                        style={{
                            marginBottom: 15,
                            paddgingLeft: 10,
                            paddingTop: 5,
                            paddingBottom: 5,
                            paddingLeft: 10,
                            backgroundColor: "#fff0f0",
                            borderWidth: 1,
                            borderColor: "#333",
                        }}
                    >
                        <span>O Campo CPF é Obrigatório.</span>
                    </div>
                ) : undefined}
                <ScrollView style={{ innerHeight: 170 }}>
                    <View
                        style={{
                            paddingBottom: 25,
                            flexDirection: "row",
                            alignItems: "center",
                        }}
                    >
                        <View>
                            <label htmlFor="file">
                                <Avatar
                                    style={{ borderWidth: 1, borderColor: Colors.primary }}
                                    src={(urlFoto ?? "").length > 0 ? urlFoto : undefined}
                                    alt={props.auth?.nome}
                                >
                                    {" "}
                                    {(props.auth?.nome ?? "Pa")?.charAt(0)}{" "}
                                </Avatar>
                            </label>
                        </View>
                        <View style={{ marginStart: 25 }}>
                            <small>* clique na foto ao lado para alterar.</small>
                            <input
                                id={"file"}
                                style={{ display: "none" }}
                                type={"file"}
                                accept="image/*"
                                onInput={async (e) => {
                                    console.log(e.target.files);
                                    let file = e.target.files[0];
                                    file.uri = e.target.value;
                                    let upload = await UploadHandler(props.auth?.id, {
                                        file,
                                        perfilId: props.auth?.perfilId,
                                        web: true,
                                    });
                                    if (upload?.success) {
                                        setUrlFoto(
                                            upload?.data?.urlFoto + "?" + moment().format("x")
                                        );

                                        let user = JSON.parse(localStorage.getItem("user_info"));
                                        user.nome = user.nome;
                                        user.sobrenome = user?.sobrenome;
                                        user.email = user?.email;
                                        user.celular = user?.celular;
                                        user.cpf = user?.cpf;
                                        user.urlFoto =
                                            upload?.data?.urlFoto + "?" + moment().format("x");

                                        localStorage.setItem("user_info", JSON.stringify(user));
                                        props.setAuth(user);
                                    }
                                    console.log("UPLOAD: ", upload, e.target.files[0]);
                                }}
                            />
                        </View>
                    </View>
                    <div>
                        <div
                            style={{
                                display: "flex",
                                flex: 1,
                                flexWrap: "wrap",
                                flexDirection: window.innerWidth >= 680 ? "row" : "column",
                                justifyContent: window.innerWidth >= 680 ? "space-around" : undefined,
                                width: window.innerWidth >= 680 ? 450 : "100%",
                            }}
                        >
                            <div>
                                <TextField
                                    size="small"
                                    style={{ width: window.innerWidth >= 680 ? 180 : "100%" }}
                                    label="Nome"
                                    variant="outlined"
                                    value={nome}
                                    onChange={(e) => {
                                        setNome(e.target.value);
                                    }}
                                />
                            </div>
                            <div style={{ marginTop: window.innerWidth >= 680 ? undefined : 10 }}>
                                <TextField
                                    size="small"
                                    style={{
                                        width: window.innerWidth >= 680 ? 260 : "100%",
                                    }}
                                    label="Sobrenome"
                                    variant="outlined"
                                    value={sobrenome}
                                    onChange={(e) => {
                                        setSobrenome(e.target.value);
                                    }}
                                />
                            </div>
                        </div>
                        <div
                            style={{
                                display: window.innerWidth >= 680 ? "flex" : undefined,
                                flex: 1,
                                flexWrap: "wrap",
                                flexDirection: window.innerWidth >= 680 ? "row" : undefined,
                                justifyContent: window.innerWidth >= 680 ? "space-around" : undefined,
                                width: window.innerWidth >= 680 ? 450 : "100%",
                                marginTop: 10,
                            }}
                        >
                            <div>
                                <TextField
                                    size="small"
                                    style={{ width: window.innerWidth >= 680 ? 180 : "100%" }}
                                    label="Celular"
                                    variant="outlined"
                                    value={telefone}
                                    onChange={(e) => {
                                        setTelefone(e.target.value);
                                    }}
                                />
                            </div>
                            <div style={{
                                marginTop: window.innerWidth >= 680 ? undefined : 10
                            }}>
                                <TextField
                                    size="small"
                                    style={{ width: window.innerWidth >= 680 ? 260 : "100%" }}
                                    label="E-mail"
                                    variant="outlined"
                                    value={email}
                                    onChange={(e) => {
                                        setEmail(e.target.value);
                                    }}
                                />
                            </div>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flex: 1,
                                flexWrap: "wrap",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                width: window.innerWidth >= 680 ? 450 : "100%",
                                marginTop: 10,
                            }}
                        >
                            <div style={{ marginTop: window.innerWidth >= 680 ? undefined : 10 }}>
                                <TextField
                                    size="small"
                                    style={{ width: window.innerWidth >= 680 ? 180 : "100%" }}
                                    label="CPF"

                                    variant="outlined"
                                    value={(cpf ?? "")?.replace(/\D/g, "")?.match(/.{1,3}/g)?.join(".")?.replace(/\.(?=[^.]*$)/, "-") ?? ""}
                                    onChange={(e) => {
                                        let val = e?.target?.value?.replace(/\D/g, "");
                                        if (val.length > 11) {
                                            return false;
                                        }
                                        setCPF(e.target.value);
                                    }}
                                />
                            </div>
                            <div></div>
                        </div>
                    </div>

                </ScrollView>
                <div style={{ marginTop: 15 }}>
                    {(requiredDocs ?? []).length > 0 ? (
                        <InputLabel style={{ marginTop: 25, marginBottom: 20, fontWeight: 'bold' }}>
                            Verificação de documentos do paciente
                        </InputLabel>) : undefined}

                    {docStatus.status > 0 ? (
                        <div
                            style={{
                                marginBottom: 15,
                                paddgingLeft: 10,
                                paddingTop: 5,
                                paddingBottom: 5,
                                paddingLeft: 10,
                                color: [1, 3].includes(docStatus.status) ? Colors.black : Colors.white,
                                backgroundColor: [1, 3].includes(docStatus.status) ? Colors.alert : "#28a745",
                                borderWidth: 1,
                                borderRadius: 5,
                                borderColor: "#333",
                            }}
                        >
                            <div>{
                                docStatus.status == 1 ?
                                    (<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}><InfoOutlined /><span> Seus documentos estão em análise.</span></div>) :
                                    docStatus.status == 2 ?
                                        (<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}><CheckCircleOutline size={11} /><span> Seus documentos estão verificados.</span></div>) :
                                        (<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}><InfoOutlined size={11} /><span> Sua verificação anterior foi reprovada.<br /> Envie os documentos pedido para uma nova verificação.</span></div>)
                            }
                            </div>
                        </div>
                    ) : undefined}

                    {(requiredDocs ?? []).length > 0 ? (

                        <div>
                            {(requiredDocs ?? []).map(doc => {
                                let exist = docs.find(f => f.id == doc?.id);
                                return (
                                    <div key={`docs-${doc.id}`} style={{ border: 1, marginTop: 20 }}>
                                        <div style={{ marginBottom: 10 }}>
                                            <InputLabel style={{ fontWeight: 'bold', textTransform: 'capitalize' }}> {doc?.nome} {doc.obrigatorio == 1 && (<b style={{ color: 'red' }}>*</b>)} </InputLabel>
                                        </div>
                                        <div>

                                            <View style={{ flexDirection: 'row' }}>
                                                <div>
                                                    <label htmlFor={`doc-${doc?.id}`}>
                                                        <a
                                                            style={{
                                                                cursor: 'pointer',
                                                                padding: 7,
                                                                backgroundColor: !exist ? Colors.primary : Colors.tertiary,
                                                                borderRadius: 5,
                                                                color: 'white',
                                                            }}>
                                                            {exist ? exist?.file?.name : "Escolher Documento"}
                                                        </a>
                                                    </label>
                                                </div>
                                                <div>
                                                    {exist && exist?.file?.name != undefined ?
                                                        (
                                                            <a href="#" style={{ marginLeft: 5, textDecoration: 'none' }} onClick={() => {
                                                                setLoading(true);
                                                                let ndocs = docs.filter(d => d.id != exist.id)
                                                                setDocs(ndocs);
                                                                setTimeout(() => { setLoading(false) }, 300);
                                                            }}>
                                                                Editar
                                                            </a>
                                                        ) : ''}
                                                </div>
                                            </View>
                                            {(loading == false && <input
                                                id={`doc-${doc?.id}`}
                                                style={{ display: 'none' }}
                                                type={"file"}
                                                onInput={(e) => {
                                                    setLoading(true);
                                                    let file = e.target.files[0];
                                                    file.uri = e.target.value;
                                                    let files = docs;
                                                    const fileInd = docs.findIndex(f => f.id == doc.id);
                                                    if (fileInd > -1) {
                                                        files.splice(fileInd, 1);
                                                    } else {
                                                        files.push({ id: doc.id, file });
                                                    }
                                                    setDocs(files);
                                                    setTimeout(() => {
                                                        setLoading(false);
                                                    }, 300);
                                                }}
                                            />)}
                                        </div>
                                        <div style={{ marginTop: 10, textAlign: 'center', padding: 10, backgroundColor: Colors.alert, width: '25%' }}>
                                            <small>{doc?.formatoArquivo?.nome}</small>
                                        </div>
                                    </div>
                                )
                            })}
                            <div ref={() => fileContainer}></div>
                        </div>
                    ) : loading == true ? <div><ActivityIndicator /></div> : undefined}
                </div>

                <div style={{ marginTop: 20, display: 'flex', flexDirection: 'row', justifyContent: 'end' }} >
                    <Button disabled={loading} variant={"contained"} color={"primary"} onClick={onSave}>
                        Gravar
                    </Button>
                </div>

            </div>
        </div>
    );
}



class UserProfile extends Component {
    state = {
        userData: undefined,
        selecteds: []
    };

    async getData() {


    }
    async componentDidMount() {
        const auth = JSON.parse(localStorage.getItem("user_info"));
        if (!auth?.id) {
            window.location.href = "/";
        } else {
            // await this.getData();
        }

    }
    render() {
        const columns = [
            {
                field: 'nome',
                headerName: 'Nome Completo',
                headerClassName: 'col-header',
                sortable: false,
                width: 350,
                flex: 1,
                valueGetter: (params) =>
                    `${params.row.nome || ''} ${params.row.sobrenome || ''}`,
            },
            {
                field: 'filiacao', headerName: 'Filiação', headerClassName: 'col-header', flex: 1, width: 130, valueGetter: (params) =>
                    `${params.row?.filiacao ?? ''}`
            },
            { field: 'cpf', headerName: 'CPF', headerClassName: 'col-header', flex: 1, width: 150 },
            {
                field: 'dataNascimento',
                headerName: 'Data de Nascimento',
                headerClassName: 'col-header',
                sortable: false,
                flex: 1,
                width: 160,
                valueGetter: (params) =>
                    params?.row?.dataNascimento ? `${moment(params?.row?.dataNascimento)?.format('DD/MM/yyyy')}` : ""
            },
            { field: 'sexo', headerName: 'Sexo', headerClassName: 'col-header', flex: 1 },
            {
                field: 'sobrenome', headerName: 'Status', flex: 1, headerClassName: 'col-header', renderCell: (params) =>
                    <b>{params?.row?.dadosAprovacao?.status?.nome ?? ''}</b>
            },
            {
                field: 'possuiAprovacao', headerName: 'Aprovação', headerClassName: 'col-header', flex: 1, width: 300, renderCell: (params) =>
                (<div style={{ zindex: 99 }}>
                    <div>
                        <div
                            style={{ color: params.row?.dadosAprovacao?.status?.id == 2 ? '#0A1' : Colors.red, paddingHorizontal: 10, paddingVertical: 5, textAlign: window.innerWidth >= 680 ? 'center' : 'start', borderRadius: 5 }}
                        >
                            {params?.row?.dadosAprovacao?.status?.id == 2 ? "SIM" : "NÃO"}
                        </div>
                    </div>
                </div>)
            },

        ];

        const rows = this.state.data;

        return (
            <Fragment>

                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', height: 500, width: '100%' }}>
                    <UserProfileInfo navigation={this.props.navigation} onSave={this.onSave} setAuth={this.props.setAuth} />
                </div>
            </Fragment>
        );
    }
}
const mapStateToProps = (store) => ({
    session: store.authReducer.data,
});
const mapDispatchToProps = (dispatch) =>
    bindActionCreators({ setSnackbar, setTimer, setProfileDialog, setAuth }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);

const styles = {
    colHeader: {
        backgroundColor: '#e3ebf2',
    },
    container: {
        paddingTop: 30,
        borderRadius: 0,
        backgroundColor: Colors.primary,
        minHeight: "100%",
    },
    secView: {
        flex: 1,
        flexDirection: "row",
        alignItems: "flex-end",
        justifyContent: "flex-end",
        paddingRight: 15,
        maxHeight: 65,
        paddingBottom: 15,
        bottom: 0,
        backgroundColor: Colors.secondary,
        width: "100%",
    },
    logoContainer: {
        alignItems: "center",
        justifyContent: "center",
        padding: 30,
    },
    logo: {
        height: 150,
        width: 150,
        resizeMode: "contain",
    },
    inputDefaultStyle: {
        height: 40,
    },
};
