import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { postResetPassword, postAuth, JWT_Decode } from "../../providers/api";
import { setDialog as authDialog, setAuth } from "../../actions/authAction";
import { setLoading, setProfileDialog } from "../../actions/appActions";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Slide from "@material-ui/core/Slide";

import { withRouter } from "react-router-dom";

import InputMask from "react-input-mask";
import Storage from "../../handlers/storage";
import { AuthInternalHandler, GetHandler, PostHandler } from "../../handlers";
import { BRANDING } from "../../../branding";
import Colors from "../../commons/Colors";
import { ActivityIndicator, ScrollView, View } from "react-native";
import moment from "moment";
import ReCAPTCHA from "react-google-recaptcha";
import { Checkbox, FormControl, InputLabel, ListItemIcon, ListItemText, MenuItem, Select } from "@material-ui/core";
import { Add, CheckCircleOutlineRounded, DeleteForever, Search } from "@material-ui/icons";
import { AutocompleteDropdown } from "react-native-autocomplete-dropdown";
import { toCNPJ, toCPF, toCep, toPhone } from "../../handlers/masks";
import { Autocomplete } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 650,
    display: "flex",
    flexDirection: "column",
    margin: "auto",
  },
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  media: {
    height: 140,
  },
}));
const yearList = []
for(let i = 1980; i <= moment().format('yyyy'); i++){
  yearList.push({
    label: i,
    value: i
  });
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Register(props) {


  const [loginError, setLoginerror] = React.useState({
    success: false,
    message: "",
  });

  const [loading, setloading] = React.useState(false);
  const [errors, setErrors] = React.useState([]);
  const [captcha, setCaptcha] = React.useState(false);
  const [cpf, setCPF] = React.useState("");
  const [cnpj, setCNPJ] = React.useState("");
  const [telefone, setTelefone] = React.useState("");


  const [codigoConfirmacao, setCodigoConfirmacao] = React.useState('');
  const [enviaDepCodConfirmacao, setEnviaDepCodConfirmacao] = React.useState(true);

  const [dados, setDados] = React.useState({});
  const [formacoesData, setFormacoesData] = React.useState([]);
  const [especialidades, setEspecialidades] = React.useState([]);
  const [grupoAtendimentoId, setGrupoAtendimentoId] = React.useState([]);
  const [formacoes, setFormacoes] = React.useState([]);
  const [tipoContrato, setTipoContrato] = React.useState(undefined);
  const [cbo, setCBO] = React.useState([]);
  const [cep, setCEP] = React.useState("");
  const [logradouro, setLogradouro] = React.useState("");
  const [uf, setUF] = React.useState("");
  const [city, setCity] = React.useState("");
  const [bairro, setBairro] = React.useState("");

  const [cadastrado, setCadastrado] = React.useState(false);

  const classes = useStyles();

  useEffect(() => {
    let auth_token = localStorage.getItem("auth_token") == '[object Object]' ? '{}' : localStorage.getItem("auth_token");

    let token = JSON.parse(auth_token);
    async function authInternal() {
      const internal = await AuthInternalHandler(BRANDING?.internal);
      console.log('INT: ', internal);
      if (internal?.success == true) {
        // Curso, Faculdade, Pós-graduação, Mestrado, Doutorado
        const dt = dados;
        dt['formAcademicaTipo'] = [""]
        dt['formAcademicaInst'] = [""]
        dt['formAcademicaFormacao'] = [""]
        dt['formAcademicaDtDe'] = [""]
        dt['formAcademicaDtAte'] = [""]

        setDados(dt);

        const formArr = formacoesData ?? [];
        formArr.push({ id: formArr.length });
        setFormacoesData(formArr);

        setFormacoes([
          { nomeFormacao: 'Curso de Formação' },
          { nomeFormacao: 'Graduação', value: 'Faculdade' },
          { nomeFormacao: 'Pós-graduação' },
          { nomeFormacao: 'Mestrado' },
          { nomeFormacao: 'Doutorado' },
        ])

        GetHandler(`gruposAtendimento`, {
          perfilId: BRANDING?.perfilId
        }).then(res => {
          setGrupoAtendimentoId(res?.data ?? []);
        }).catch(e => {
        });

        GetHandler(`especialidades`, {
          perfilId: BRANDING?.perfilId
        }).then(res => {
          setEspecialidades(res?.data?.data ?? []);
        }).catch(e => {
        });
      }
    }

    authInternal();
    // return false;
  }, []);

  function onChangeFormacao(e, index) {
    console.log(e.target.value)
    let dt = dados;
    let value = e.target.value;
    if (Array.isArray(e.target.value)) {
      value = value[0];
    }
    if (dt[e.target?.id ?? e.target?.name] == undefined) {
      dt[e.target?.id ?? e.target?.name] = [];
    }

    dt[e.target?.id ?? e.target?.name][index] = value

    console.log('FORMACAO DADOS: ', dt)
    setDados(dt);
  }
  function onChange(e) {
    let dt = dados;
    if ((e.target?.id ?? e.target?.name) == "cpf") {
      if (e.target.value.replace(/\D/g, "").length >= 11) {
        return false;
      }
      dt[e.target?.id ?? e.target?.name] = e.target.value.replace(/\D/g, "");
    } else {
      if (Array.isArray(e.target.value)) {
        if ((dt[e.target?.id ?? e.target?.name] ?? []).length > 0) {
          dt[e.target?.id ?? e.target?.name].push(e.target.value[0])
        } else {
          dt[e.target?.id ?? e.target?.name] = [];
          dt[e.target?.id ?? e.target?.name]?.push(e.target.value[0])
        }
      } else {
        dt[e.target?.id ?? e.target?.name] = e.target.value
      }

    }
    setDados(dt);
  }

  const validate = () => {
    setloading(true);
    let err = [];

    [
      { field: 'nome', description: 'Nome' },
      { field: 'sexo', description: 'Sexo' },
      { field: 'email', description: 'E-mail' },
      { field: 'tipoContrato', description: 'Tipo de Contrato' },
      { field: 'sobre', description: 'Curriculo' },
      { field: 'dataIniProf', description: 'Data de inicio Profissional' },
      { field: 'telefone', description: 'Telefone' },
      { field: 'especialidadeNome', description: 'Especialidade' },
      { field: 'conselhoProfissionalId', description: 'Conselho Profissional' },
      { field: 'conselhoProfissionalUf', description: 'UF do conselho' },
      { field: 'conselhoProfissionalNumero', description: 'Número do Conselho' },
      { field: 'grupoAtendimentoId', description: 'Grupos de Atendimento' },
      { field: 'idiomasId', description: 'Idiomas' },
      { field: 'formAcademicaTipo', description: 'Formação' },
      { field: 'cep', description: 'CEP' },
      { field: 'uf', description: 'Estado' },
      { field: 'bairro', description: 'Bairro' },
      { field: 'logradouro', description: 'Logradouro' },
      { field: 'numero', description: 'Número' },
    ].map(item => {
      if (dados.hasOwnProperty(item?.field)) {

        if (dados[item?.field].length == 0) {
          err.push({ field: item?.field, description: item?.description, message: 'é Obrigatório' })
        }

      } else {
        err.push({ field: item?.field, description: item?.description, message: 'é Obrigatório' })
      }
    })
    setErrors(err);
    setDados(dados);
    console.log('ERROS: ', errors);
    setTimeout(() => setloading(false), 1000)
    return err;
  }

  // Cadastro
  const registerHandler = async (e) => {
    e.preventDefault();



    if (validate().length == 0) {
      setLoading(true)

      let dadosn = dados;
      if ((dados?.tagsTratamento ?? "")?.length > 0) {
        dadosn.tagsTratamento = (dados?.tagsTratamento ?? "")?.split(',');
      }

      const data = { ...dadosn, perfilId: BRANDING?.perfilId, aprovacao: true }

      const register = await PostHandler(
        //"registrarPacienteLogin",
        "doutores",
        data,
        props.navigation
      );
      if (register?.data?.success ?? register?.success) {
        let message = register?.data?.message ?? "Enviado com Sucesso!";
        if (register?.success == true) {
          setCadastrado(true);
          setTimeout(function () {
            window.location.href = "https://ibac.com.br/cadastro-realizado/";
          }, 5000)
        } else {
          alert(message);
        }

        setLoading(false)
        return;
      }

      alert(
        "Problema ao cadastrar.\n" +
        (register?.message ?? register?.data?.message ?? "")
      );
      setLoading(false)
    } else {
      const errorsStr = validate()[0]?.description; //.map(er => er.description).join(', ');
      // console.log(errorsStr);
      alert(`O campo ${errorsStr} é obrigatório, favor verificar!`)
    }
  };
  // Login
  async function onSubmit(e) {
    e.preventDefault();
    // setloading(true);

    let token = JSON.parse(localStorage.getItem("auth_token") ?? {});
    let data = await postAuth({ ...dados }, token?.token);

    console.log('DADOS CADASTRADOS : ', dados);
    return false;

    if (data?.error == undefined) {
      if (data.data.length > 0 && data.data[0]?.id > 0) {
        localStorage.setItem("user_info", JSON.stringify(data.data[0]));
        //localStorage.setItem("token", data.data[0].token);
        props.setAuth(data.data[0]);
        setLoginerror({ success: false, message: "" });
        setloading(false);
        let schedule = scheduleData;

        if (scheduleData?.data || scheduleData?.favorito != undefined) {
          let schedule = scheduleData;

          schedule.pacienteId = data.data[0]?.id;
          schedule.perfilId = data.data[0]?.perfilId;
          schedule.paciente = {
            nome: data.data[0]?.nome
              ? `${data.data[0]?.nome} ${data.data[0]?.sobrenome}`
              : "",
            cpf: data.data[0]?.cpf ?? "",
            email: data.data[0]?.email ?? "",
          };

          if (scheduleData?.favorito == true) {
            let req = await PostHandler(
              `pacientes/${schedule?.pacienteId}/favoritos?perfilId=${schedule?.perfilId}`,
              {
                idDoutor: schedule?.doutorId,
              }
            );

            if (req?.data?.success) {
              window.location.href = "/favorites";
            }

            return;
          }
          const scheduling = await PostHandler(
            `consultas`,
            schedule,
            props.navigation
          );

          if (scheduling?.success) {
            let body =
              "O seu horário foi reservado, e será confirmado após efetuar o pagamento !";
            body +=
              "\nDeseja ser redirecionado para pagamento da sessão agora ?";
            props.setProfileDialog({
              title: "Atenção",
              body,
              open: true,
              type: "yesno",
              onSave: () => {
                window.open(
                  scheduling?.data?.data?.linkCobranca?.linkCobranca,
                  "_blank"
                );
                window.location.href = "/history";
              },
              onCancel: () => {
                window.location.href = "/history";
              },
            });
          }
        } else {
          window.location.href = "/";
        }
      } else {
        setLoginerror({ success: true, message: data.data.message });
        setloading(false);
      }
    }
  }
  //

  if (cadastrado == true) {
    return (
      <div style={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'center' }}>
        <div>
          <div style={{ marginTop: 30, backgroundColor: Colors.white, maxWidth: 950, padding: 10, borderRadius: 10 }}>
            <ScrollView style={{ innerHeight: 170 }}>
              <div>
                <View
                  style={{
                    marginTop: 25,
                    paddingBottom: 25,
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                >
                  <CheckCircleOutlineRounded color={"secondary"} size={100} />
                  <InputLabel style={{ marginBottom: 5, marginTop: 10 }}>
                    Seu cadastro foi enviado para aprovação com sucesso!
                  </InputLabel>
                </View>
              </div>
            </ScrollView>
          </div>
        </div>
      </div>
    )
  }

  if (enviaDepCodConfirmacao == false) {
    return (
      <div style={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'center' }}>
        <div>
          <div style={{ marginTop: 30, backgroundColor: Colors.white, maxWidth: 950, padding: 10, borderRadius: 10 }}>
            <ScrollView style={{ innerHeight: 170 }}>
              <div>
                <View
                  style={{
                    marginTop: 10,
                    paddingBottom: 25,
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                >
                  <InputLabel style={{ marginBottom: 15 }}>Insira o código de confirmação</InputLabel>
                  <TextField
                    size="small"
                    style={{ width: window.innerWidth >= 680 ? 180 : "100%" }}
                    label="Código"
                    variant="outlined"
                    value={codigoConfirmacao}
                    onChange={(e) => {
                      setCodigoConfirmacao(e.target.value);
                    }}
                  />
                </View>
              </div>
            </ScrollView>
          </div>
          <div style={{ marginTop: 20, display: 'flex', flexDirection: 'row', justifyContent: 'center' }} >
            <Button disabled={loading} variant={"contained"} color={"primary"} onClick={registerHandler}>
              Enviar
            </Button>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div>
      <div style={{ marginBottom: 25, marginTop: 35 }}>
        <img
          src={BRANDING?.loginLogo?.img}
          style={{ display: "flex", margin: "auto", marginBottom: 15 }}
          height={BRANDING?.loginLogo?.height}
        />
      </div>

      <form id="registerForm" onSubmit={registerHandler}>
        <div style={{ display: 'flex', flexDirection: window.innerWidth > 600 ? 'row' : 'column', justifyContent: 'space-between' }}>

          <div style={{ width: window.innerWidth > 600 ? '100%' : '100%', marginBottom: 25 }}>

            <Card className={classes.root}>
              <CardContent>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div style={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', flexBasis: window.innerWidth > 600 ? '49%' : '100%' }}>
                    <div style={{ width: '100%' }}>
                      <TextField
                        InputLabelProps={{
                          style: { fontWeight: 'bold' },
                        }}
                        error={errors.find(err => err?.field == "nome")?.field != undefined}
                        helperText={"* Obrigatório"}
                        type="text"
                        fullWidth
                        id="nome"
                        label="Nome"
                        variant="outlined"
                        onChange={onChange}
                        onBlur={onChange}
                      />
                    </div>

                  </div>
                  <br />
                  <div style={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', flexBasis: window.innerWidth > 600 ? '45%' : '100%', flexWrap: 'wrap' }}>

                    <div style={{ width: window.innerWidth > 600 ? '45%' : '100%', marginBottom: 10 }}>
                      <View
                        style={{
                          width: window.innerWidth >= 680 ? "100%" : "100%",
                          marginEnd: 15,
                          marginTop: window.innerWidth >= 680 ? undefined : 10,
                        }}
                      >
                        <InputLabel style={{ fontSize: 12, marginBottom: 5, fontWeight: 'bold' }} id="sexo-select-label">
                          Sexo
                        </InputLabel>
                        <FormControl sx={{ m: 1 }} size="small">
                          <Select
                            size={'small'}
                            labelId="sexo-select-label"
                            error={errors.find(err => err?.field == "sexo")}
                            helperText={"* Obrigatório"}
                            id="sexo"
                            name="sexo"
                            label="Sexo do Doutor"
                            onChange={onChange}
                          >
                            <MenuItem key={`sexo-selection`} value={"M"}>
                              {`Masculino`}
                            </MenuItem>
                            {([{
                              label: "Feminino",
                              value: "F"
                            }, {
                              label: "Outro",
                              value: "O"
                            },]).map((item) => (
                              <MenuItem
                                key={`sexo-${item.value}`}
                                value={item.value}
                              >
                                {item.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </View>
                    </div>
                    <div style={{ width: window.innerWidth > 600 ? '45%' : '100%', marginBottom: 10 }}>
                      <TextField
                        InputLabelProps={{
                          style: { fontWeight: 'bold' },
                        }}
                        error={errors.find(err => err?.field == "email")}
                        helperText={"* Obrigatório"}
                        type="text"
                        fullWidth
                        id="email"
                        label="E-mail"
                        variant="outlined"
                        onChange={onChange}
                        onBlur={onChange}
                      />
                    </div>
                  </div>
                  <br />
                  <div style={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', flexBasis: window.innerWidth > 600 ? '45%' : '100%', flexWrap: 'wrap' }}>
                    <div style={{ width: window.innerWidth > 600 ? '45%' : '100%', marginBottom: 10 }}>
                      <View
                        style={{
                          width: window.innerWidth >= 680 ? "100%" : "100%",
                          marginEnd: 15,
                          marginTop: window.innerWidth >= 680 ? undefined : 10,
                        }}
                      >
                        <InputLabel style={{ fontSize: 12, marginBottom: 5, fontWeight: 'bold' }} id="indicacao-select-label">
                          Tipo de Contrato
                        </InputLabel>
                        <FormControl sx={{ m: 1 }} size="small">
                          <Select
                            size={'small'}
                            labelId="tipoContrato-select-label"
                            id="tipoContrato"
                            name="tipoContrato"
                            label="Tipo de contrato"
                            error={errors.find(err => err?.field == "tipoContrato")}
                            helperText={"* Obrigatório"}

                            onChange={(e) => {
                              onChange(e)
                              setTipoContrato(e.target?.value);
                            }}
                          >
                            <MenuItem key={`tipoContrato-selection`} value={1}>
                              {`Pessoa Jurídica`}
                            </MenuItem>
                            {([{
                              value: 2,
                              label: 'Autônomo'
                            }]).map((item) => (
                              <MenuItem
                                key={`tipoContrato-${item.value}`}
                                value={item.value}
                              >
                                {item.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </View>
                    </div>

                    {tipoContrato == 2 ? (
                      <div style={{ width: window.innerWidth > 600 ? '45%' : '100%', marginBottom: 10 }}>
                        <TextField
                          InputLabelProps={{
                            style: { fontWeight: 'bold' },
                          }}
                          error={loginError.success}
                          type="text"
                          fullWidth
                          id="cpf"
                          label="CPF"
                          variant="outlined"
                          //onBlur={onChange}
                          onChange={(e) => {
                            let dt = dados;
                            let val = e.target.value.replace(/\D/g, "");

                            if (val.length > 11) {
                              return false;
                            }

                            dt["cpf"] = val; ///.replace(/\D/g, "");
                            setDados(dt);
                            setCPF(toCPF(e.target.value))
                          }}
                          value={(cpf ?? "")}
                        />
                      </div>) : tipoContrato == 1 ?
                      (<div style={{ width: window.innerWidth > 600 ? '45%' : '100%', marginBottom: 10 }}>
                        <TextField
                          InputLabelProps={{
                            style: { fontWeight: 'bold' },
                          }}
                          error={loginError.success}
                          type="text"
                          fullWidth
                          id="cnpj"
                          label="CNPJ"
                          variant="outlined"
                          //onBlur={onChange}
                          onChange={(e) => {
                            let dt = dados;
                            let val = e.target.value.replace(/\D/g, "");

                            if (val.length > 14) {
                              return false;
                            }

                            dt["cnpj"] = val; ///.replace(/\D/g, "");
                            setDados(dt);
                            setCNPJ(toCNPJ(e.target.value))
                          }}
                          value={cnpj}
                        />
                      </div>) : undefined}
                    
                  </div>
                  <br />

                  <div style={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', flexBasis: window.innerWidth > 600 ? '45%' : '100%', flexWrap: 'wrap' }}>
                    <div style={{ width: '100%' }}>
                      <TextField
                        InputLabelProps={{
                          style: { fontWeight: 'bold' },
                        }}
                        error={errors.find(err => err?.field == "sobre")}
                        helperText={"* Obrigatório"}
                        type="text"
                        multiline={true}
                        fullWidth
                        id="sobre"
                        label="Currículo"
                        variant="outlined"
                        onChange={onChange}
                        onBlur={onChange}
                      />
                    </div>
                  </div>
                  <br />


                  <div style={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', flexBasis: window.innerWidth > 600 ? '45%' : '100%', flexWrap: 'wrap' }}>

                  </div>
                  <br />

                  <div style={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', flexBasis: window.innerWidth > 600 ? '45%' : '100%', flexWrap: 'wrap' }}>
                    <div style={{ width: window.innerWidth > 600 ? '45%' : '100%', marginBottom: 10 }}>
                      <TextField
                        InputLabelProps={{
                          style: { fontWeight: 'bold' },
                        }}
                        fullWidth
                        error={errors.find(err => err?.field == "dataIniProf")}
                        helperText={"* Obrigatório"}
                        id="dataIniProf"
                        focused={true}
                        label="Data de início da atividade profissional:"
                        variant="outlined"
                        type="date"
                        onChange={onChange}
                        onBlur={onChange}
                      />
                    </div>
                    <div style={{ width: window.innerWidth > 600 ? '45%' : '100%', marginBottom: 10 }}>

                      <TextField
                        InputLabelProps={{
                          style: { fontWeight: 'bold' },
                        }}
                        error={errors.find(err => err?.field == "telefone")}
                        helperText={"* Obrigatório"}
                        type="text"
                        onFocus={(e) => {
                          e.target.select();
                        }}
                        fullWidth
                        id="telefone"
                        label="Telefone"
                        variant="outlined"
                        onChange={(e) => {
                          let dt = dados;
                          let val = e.target.value.replace(/\D/g, "");

                          if (val.length > 11) {
                            return false;
                          }

                          dt["telefone"] = val; ///.replace(/\D/g, "");
                          setDados(dt);
                          setTelefone(toPhone(e.target.value))
                        }}
                        value={telefone}
                      />
                    </div>
                  </div>
                  <br />
                  <div style={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', flexBasis: window.innerWidth > 600 ? '45%' : '100%', flexWrap: 'wrap' }}>
                  </div>

                  <div style={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', flexBasis: window.innerWidth > 600 ? '45%' : '100%', flexWrap: 'wrap' }}>
                    <div style={{ width: window.innerWidth > 600 ? '45%' : '100%', marginBottom: 10 }}>
                      <View
                        style={{
                          width: window.innerWidth >= 680 ? "100%" : "100%",
                          marginEnd: 15,
                          marginTop: window.innerWidth >= 680 ? undefined : 10,
                        }}
                      >
                        <InputLabel style={{ fontSize: 12, marginBottom: 5, fontWeight: 'bold' }} id="pais-select-label">
                          Especialidade
                        </InputLabel>
                        <FormControl sx={{ m: 1 }} size="small">
                          <Select
                            size={'small'}
                            renderValue={(e) => {
                              return e?.join(', ')
                            }}
                            multiple={true}
                            value={dados?.especialidadeNome ?? []}
                            labelId="especialidadeNome-select-label"
                            id="especialidadeNome"
                            name="especialidadeNome"
                            label="Especialidade"
                            error={errors.find(err => err?.field == "especialidadeNome")}
                            helperText={"* Obrigatório"}
                          >
                            {(especialidades ?? []).map((item) => {
                              let checked = (dados?.especialidadeNome ?? [])?.find(i => i == item?.nome) ? true : false;
                              return (
                                <MenuItem
                                  key={`especialidadeNome-${item?.nome}`}
                                  value={item?.nome}
                                >
                                  <ListItemIcon>
                                    <Checkbox checked={checked} onChange={(e) => {
                                      if (!e.target.checked) {
                                        let index = (dados.especialidadeNome ?? [""])?.findIndex(i => i == item?.nome)
                                        let especialidadeNome = dados.especialidadeNome ?? [];
                                        especialidadeNome.splice(index, 1);
                                        setDados({ ...dados, especialidadeNome });

                                      } else {
                                        let especialidadeNome = dados.especialidadeNome ?? [];
                                        especialidadeNome.push(item?.nome);
                                        setDados({ ...dados, especialidadeNome });

                                      }
                                    }} />
                                  </ListItemIcon>
                                  <ListItemText primary={`${item?.nome}`} />
                                </MenuItem>
                              )
                            })}
                          </Select>
                        </FormControl>
                      </View>
                    </div>
                    <div style={{ width: window.innerWidth > 600 ? '45%' : '100%', marginBottom: 10 }}>

                    </div>
                  </div>
                  <br />

                  <div style={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', flexBasis: window.innerWidth > 600 ? '45%' : '100%', flexWrap: 'wrap' }}>
                    <div style={{ width: window.innerWidth > 600 ? '45%' : '100%', marginBottom: 10 }}>
                      <View
                        style={{
                          width: window.innerWidth >= 680 ? "100%" : "100%",
                          marginEnd: 15,
                          marginTop: window.innerWidth >= 680 ? undefined : 10,
                        }}
                      >
                        <InputLabel style={{ fontSize: 12, marginBottom: 5, fontWeight: 'bold' }} id="conselhoProfissionalId-select-label">
                          Conselho Profissional
                        </InputLabel>
                        <FormControl sx={{ m: 1 }} size="small">
                          <Select
                            size={'small'}
                            labelId="conselhoProfissionalId-select-label"
                            id="conselhoProfissionalId"
                            name="conselhoProfissionalId"
                            label="conselho profissional"
                            error={errors.find(err => err?.field == "conselhoProfissionalId")}
                            helperText={"* Obrigatório"}
                            onChange={onChange}
                          >
                            <MenuItem key={`conselhoProfissionalId-selection`} value={"1"}>
                              {`CRAS - Conselho Regional de Assistência Social`}
                            </MenuItem>
                            <MenuItem key={`conselhoProfissionalId-selection`} value={"2"}>
                              {`COREN - Conselho Regional de Enfermagem`}
                            </MenuItem>
                            <MenuItem key={`conselhoProfissionalId-selection`} value={"3"}>
                              {`CRF - Conselho Regional de Farmácia`}
                            </MenuItem>
                            <MenuItem key={`conselhoProfissionalId-selection`} value={"4"}>
                              {`CRFA - Conselho Regional de Fonoaudiologia`}
                            </MenuItem>
                            <MenuItem key={`conselhoProfissionalId-selection`} value={"5"}>
                              {`CREFITO - Conselho Regional de Fisioterapia e Terapia Ocupacional`}
                            </MenuItem>
                            <MenuItem key={`conselhoProfissionalId-selection`} value={"6"}>
                              {`CRM - Conselho regional de medicina`}
                            </MenuItem>
                            <MenuItem key={`conselhoProfissionalId-selection`} value={"7"}>
                              {`CRN - Conselho Regional de Nutrição`}
                            </MenuItem>
                            <MenuItem key={`conselhoProfissionalId-selection`} value={"8"}>
                              {`CRO - Conselho Regional de Odontologia`}
                            </MenuItem>
                            <MenuItem key={`conselhoProfissionalId-selection`} value={"9"}>
                              {`CRP - Conselho Regional de Psicologia`}
                            </MenuItem>
                            <MenuItem key={`conselhoProfissionalId-selection`} value={"10"}>
                              {`OUTROS - Outros Conselhos`}
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </View>
                    </div>
                    <div style={{ width: window.innerWidth > 600 ? '45%' : '100%', marginBottom: 10 }}>
                      <View
                        style={{
                          width: window.innerWidth >= 680 ? "100%" : "100%",
                          marginEnd: 15,
                          marginTop: window.innerWidth >= 680 ? undefined : 10,
                        }}
                      >
                        <InputLabel style={{ fontSize: 12, marginBottom: 5, fontWeight: 'bold' }} id="conselhoProfissionalUf-select-label">
                          UF do conselho
                        </InputLabel>
                        <FormControl sx={{ m: 1 }} size="small">
                          <Select
                            size={'small'}
                            labelId="conselhoProfissionalUf-select-label"
                            id="conselhoProfissionalUf"
                            name="conselhoProfissionalUf"
                            label="conselho profissional"
                            error={errors.find(err => err?.field == "conselhoProfissionalUf")}
                            helperText={"* Obrigatório"}
                            onChange={onChange}
                          >
                            {([
                              { nome: "Acre", sigla: "AC" },
                              { nome: "Alagoas", sigla: "AL" },
                              { nome: "Amapá", sigla: "AP" },
                              { nome: "Amazonas", sigla: "AM" },
                              { nome: "Bahia", sigla: "BA" },
                              { nome: "Ceará", sigla: "CE" },
                              { nome: "Distrito Federal", sigla: "DF" },
                              { nome: "Espírito Santo", sigla: "ES" },
                              { nome: "Goiás", sigla: "GO" },
                              { nome: "Maranhão", sigla: "MA" },
                              { nome: "Mato Grosso", sigla: "MT" },
                              { nome: "Mato Grosso do Sul", sigla: "MS" },
                              { nome: "Minas Gerais", sigla: "MG" },
                              { nome: "Pará", sigla: "PA" },
                              { nome: "Paraíba", sigla: "PB" },
                              { nome: "Paraná", sigla: "PR" },
                              { nome: "Pernambuco", sigla: "PE" },
                              { nome: "Piauí", sigla: "PI" },
                              { nome: "Rio de Janeiro", sigla: "RJ" },
                              { nome: "Rio Grande do Norte", sigla: "RN" },
                              { nome: "Rio Grande do Sul", sigla: "RS" },
                              { nome: "Rondônia", sigla: "RO" },
                              { nome: "Roraima", sigla: "RR" },
                              { nome: "Santa Catarina", sigla: "SC" },
                              { nome: "São Paulo", sigla: "SP" },
                              { nome: "Sergipe", sigla: "SE" },
                              { nome: "Tocantins", sigla: "TO" }

                            ] ?? []).map((item) => (
                              <MenuItem
                                key={`indicacao-${item?.nome}`}
                                value={item?.sigla}
                              >
                                {item?.sigla} - {item?.nome}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </View>
                    </div>
                  </div>
                  <br />

                  <div style={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', flexBasis: window.innerWidth > 600 ? '45%' : '100%', flexWrap: 'wrap' }}>
                    <div style={{ width: window.innerWidth > 600 ? '45%' : '100%', marginBottom: 10 }}>
                      <TextField
                        InputLabelProps={{
                          style: { fontWeight: 'bold' },
                        }}
                        type="number"
                        fullWidth
                        id="conselhoProfissionalNumero"
                        label={"Número no Conselho*"}
                        variant="outlined"
                        error={errors.find(err => err?.field == "conselhoProfissionalNumero")}
                        helperText={"* Obrigatório"}
                        onChange={onChange}
                        onBlur={onChange}
                      />
                    </div>
                    <div style={{ width: window.innerWidth > 600 ? '45%' : '100%', marginBottom: 10 }}>

                    </div>
                  </div>
                  <br />

                  <div style={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', flexBasis: window.innerWidth > 600 ? '45%' : '100%', flexWrap: 'wrap' }}>
                    <div style={{ width: window.innerWidth > 600 ? '45%' : '100%', marginBottom: 10 }}>
                      <View
                        style={{
                          width: window.innerWidth >= 680 ? "100%" : "100%",
                          marginEnd: 15,
                          marginTop: window.innerWidth >= 680 ? undefined : 10,
                        }}
                      >
                        <InputLabel style={{ fontSize: 12, marginBottom: 5, fontWeight: 'bold' }} id="indicacao-select-label">
                          Grupos de Atendimento
                        </InputLabel>
                        <FormControl sx={{ m: 1 }} size="small">
                          <Select
                            size={'small'}
                            multiple={true}
                            renderValue={(e) => {
                              const render = [];
                              e.forEach((a) => {
                                render.push(grupoAtendimentoId.find(i => i?.id == a)?.nome)
                              })

                              return render?.join(', ');
                            }}
                            value={dados?.grupoAtendimentoId ?? []}
                            labelId="grupoAtendimentoId-select-label"
                            id="grupoAtendimentoId"
                            name="grupoAtendimentoId"
                            label="Selecione um ou mais grupos de atendimento"
                            error={errors.find(err => err?.field == "grupoAtendimentoId")}
                            helperText={"* Obrigatório"}
                          >
                            {(grupoAtendimentoId ?? []).map((item) => {
                              let checked = (dados.grupoAtendimentoId ?? [])?.find(i => i == item?.id) ? true : false;
                              return (
                                <MenuItem
                                  key={`grupoAtendimentoId-${item?.id}`}
                                  value={item?.id}
                                >
                                  <ListItemIcon>
                                    <Checkbox checked={checked} onChange={(e) => {
                                      if (!e.target.checked) {
                                        let index = (dados.grupoAtendimentoId ?? [""]).findIndex(i => i == item?.id)
                                        let grupoAtendimentoId = dados.grupoAtendimentoId ?? [];
                                        grupoAtendimentoId.splice(index, 1);
                                        setDados({ ...dados, grupoAtendimentoId });
                                      } else {
                                        let grupoAtendimentoId = dados.grupoAtendimentoId ?? [];
                                        grupoAtendimentoId.push(item?.id);
                                        setDados({ ...dados, grupoAtendimentoId });
                                      }
                                    }} />
                                  </ListItemIcon>
                                  <ListItemText primary={item?.nome} />
                                </MenuItem>
                              )
                            })}
                          </Select>
                        </FormControl>
                      </View>
                    </div>
                    <div style={{ width: window.innerWidth > 600 ? '45%' : '100%', marginBottom: 10 }}>
                      <View
                        style={{
                          width: window.innerWidth >= 680 ? "100%" : "100%",
                          marginEnd: 15,
                          marginTop: window.innerWidth >= 680 ? undefined : 10,
                        }}
                      >
                        <InputLabel style={{ fontSize: 12, marginBottom: 5, fontWeight: 'bold' }} id="idiomasId-select-label">
                          Idiomas
                        </InputLabel>
                        <FormControl sx={{ m: 1 }} size="small">
                          <Select
                            size={'small'}
                            multiple={true}
                            renderValue={(e) => {
                              let idiomas = [
                                {
                                  value: 1,
                                  label: 'Português'
                                }, {
                                  value: 4,
                                  label: 'Alemão'
                                }, {
                                  value: 3,
                                  label: 'Espanhol'
                                }, , {
                                  value: 2,
                                  label: 'Inglês'
                                }];
                              const render = [];
                              e.forEach((a) => {
                                console.log(idiomas.find(i => i?.value == a))
                                render.push(idiomas.find(i => i?.value == a)?.label)
                              })

                              return render?.join(', ');
                            }}
                            value={dados?.idiomasId ?? []}
                            labelId="idiomasId-select-label"
                            id="idiomasId"
                            name="idiomasId"
                            label="Selecione um ou mais Idiomas de atendimento"
                            error={errors.find(err => err?.field == "idiomasId")}
                            helperText={"* Obrigatório"}
                          >
                            {([{
                              value: 1,
                              label: 'Português'
                            }, {
                              value: 4,
                              label: 'Alemão'
                            }, {
                              value: 3,
                              label: 'Espanhol'
                            }, , {
                              value: 2,
                              label: 'Inglês'
                            },]).map((item) => {
                              let checked = (dados.idiomasId ?? []).find(i => i == item?.value) ? true : false;
                              return (
                                <MenuItem
                                  key={`indicacao-${item.value}`}
                                  value={item.value}
                                >
                                  <ListItemIcon>
                                    <Checkbox checked={checked} onChange={(e) => {
                                      if (!e.target.checked) {
                                        let index = (dados.idiomasId ?? [""]).findIndex(i => i == item?.value)
                                        let idiomasId = dados.idiomasId ?? [];
                                        idiomasId.splice(index, 1);
                                        setDados({ ...dados, idiomasId });
                                      } else {
                                        let idiomasId = dados.idiomasId ?? [];
                                        idiomasId.push(item?.value);
                                        setDados({ ...dados, idiomasId });
                                      }
                                    }} />
                                  </ListItemIcon>
                                  <ListItemText primary={item?.label} />
                                </MenuItem>
                              )
                            }
                            )}
                          </Select>
                        </FormControl>
                      </View>
                    </div>
                  </div>
                  <br />
                  <br />
                  <div style={{ marginTop: 15, marginBottom: 15, }}>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between',  borderBottom: '1px solid', borderColor: '#111', paddingBottom: 10 }}>
                      <View>
                        <InputLabel style={{ fontWeight: 'bold' }}>Formação Acadêmica: </InputLabel>
                      </View>
                      <View style={{ width: 56 }}>
                        <Button
                          variant="contained"
                          style={{backgroundColor: '#6495ED'}}
                          disableElevation
                          size="small"
                          loading={loading}
                          onClick={() => {
                            setloading(true)
                            const formArr = formacoesData ?? [];
                            formArr.push({ id: formArr.length });

                            const dt = dados;
                            if (dt['formAcademicaTipo'] == undefined || dt['formAcademicaTipo']?.length == 0) {
                              dt['formAcademicaTipo'] = []
                              dt['formAcademicaInst'] = []
                              dt['formAcademicaFormacao'] = []
                              dt['formAcademicaDtDe'] = []
                              dt['formAcademicaDtAte'] = []
                            }

                            dt['formAcademicaTipo'].push("")
                            dt['formAcademicaInst'].push("")
                            dt['formAcademicaFormacao'].push("")
                            dt['formAcademicaDtDe'].push("")
                            dt['formAcademicaDtAte'].push("")
                            setDados(dt);

                            console.log('DADOS: ', formArr.length, dados)

                            setFormacoesData(formArr);
                            setTimeout(() => setloading(false), 100)
                          }}>
                          <Add  style={{color: '#fff'}} />
                        </Button>
                      </View>
                    </View>

                    <br />
                    {formacoesData?.map((form, formIndex) => {
                      return (
                      <div key={`formacaoData-${formIndex}`} style={{ marginTop: 10, marginBottom: 25, backgroundColor: '#f9f9f9', padding: 5 }}>
                        <div style={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', flexBasis: window.innerWidth > 600 ? '45%' : '100%', flexWrap: 'wrap' }}>
                          <div style={{ width: '100%' }}>
                            <View
                              style={{
                                width: window.innerWidth >= 680 ? "100%" : "100%",
                                marginEnd: 15,
                                marginTop: window.innerWidth >= 680 ? undefined : 10,
                              }}
                            >
                              <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                <View style={{ width: '72%' }}>
                                  <InputLabel style={{ fontSize: 12, marginBottom: 5, fontWeight: 'bold' }} id="formAcademicaTipo-select-label">
                                    Tipo de Formação:
                                  </InputLabel>

                                  <FormControl sx={{ m: 1 }} size="small">
                                    <Select
                                      size={'small'}
                                      labelId="formAcademicaTipo-select-label"
                                      id="formAcademicaTipo"
                                      name="formAcademicaTipo"
                                      label="Selecione um ou mais Idiomas de atendimento"

                                      onChange={(e) => {
                                        let el = e;
                                        el.target.value = e?.target?.value == 'Graduação' ? 'Faculdade' : e?.target?.value;
                                        onChangeFormacao(el, form?.id)
                                        console.log('DADOS: ', dados);
                                      }}
                                    >
                                      {(formacoes ?? []).map((item) => (
                                        <MenuItem
                                          key={`indicacao-${item?.nomeFormacao}`}
                                          value={item?.nomeFormacao}
                                        >
                                          {item?.nomeFormacao}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </View>
                                <View>
                                  <Button
                                    variant="contained"
                                    style={{backgroundColor: '#DC143C'}}
                                    disableElevation
                                    size="small"
                                    onClick={() => {
                                      setloading(true)
                                      const formArr = formacoesData.filter(val => val?.id != form?.id);
                                      setFormacoesData(formArr);


                                      const dt = dados
                                      dt['formAcademicaTipo'].splice(formIndex, 1)
                                      dt['formAcademicaInst'].splice(formIndex, 1)
                                      dt['formAcademicaFormacao'].splice(formIndex, 1)
                                      dt['formAcademicaDtDe'].splice(formIndex, 1)
                                      dt['formAcademicaDtAte'].splice(formIndex, 1)
                                      setDados(dt);

                                      console.log(form.id, dados);
                                      setTimeout(() => setloading(false), 100)
                                    }}>
                                    <DeleteForever style={{color: '#fff'}} />
                                  </Button>
                                </View>
                              </View>
                            </View>
                          </div>
                        </div>
                        <br />

                        <div style={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', flexBasis: window.innerWidth > 600 ? '45%' : '100%', flexWrap: 'wrap' }}>
                          <div style={{ width: window.innerWidth > 600 ? '45%' : '100%', marginBottom: 10 }}>
                            <TextField
                              InputLabelProps={{
                                style: { fontWeight: 'bold' },
                              }}
                              error={loginError.success}
                              helperText={loginError.success ? loginError.message : ""}
                              type="text"
                              fullWidth
                              id="formAcademicaInst"

                              label="Instituição de ensino:"
                              variant="outlined"
                              onChange={(e) => onChangeFormacao(e, form?.id)}
                              onBlur={(e) => onChangeFormacao(e, form?.id)}
                            />
                          </div>
                          <div style={{ width: window.innerWidth > 600 ? '45%' : '100%', marginBottom: 10 }}>
                            <TextField
                              InputLabelProps={{
                                style: { fontWeight: 'bold' },
                              }}
                              error={loginError.success}
                              helperText={loginError.success ? loginError.message : ""}
                              type="text"
                              fullWidth
                              id="formAcademicaFormacao"

                              label="Formação:"
                              variant="outlined"
                              onChange={(e) => onChangeFormacao(e, form?.id)}
                              onBlur={(e) => onChangeFormacao(e, form?.id)}
                            />
                          </div>
                        </div>
                        <br />

                        <div style={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', flexBasis: window.innerWidth > 600 ? '45%' : '100%', flexWrap: 'wrap' }}>
                          <div style={{ width: window.innerWidth > 600 ? '45%' : '100%', marginBottom: 10 }}>
                            <Autocomplete
                              disablePortal
                              id="formAcademicaDtDe"
                              options={yearList}
                              renderInput={(params) => <TextField
                                {...params} 
                                InputLabelProps={{
                                  style: { fontWeight: 'bold' },
                                }}
                                label="De:"
                                variant="outlined"
                                error={loginError.success}
                                helperText={loginError.success ? loginError.message : ""}
                                fullWidth
                              />}
                              onChange={(e) => onChangeFormacao(e, form?.id)}
                              onBlur={(e) => onChangeFormacao(e, form?.id)}
                            />
                            
                          </div>
                          <div style={{ width: window.innerWidth > 600 ? '45%' : '100%', marginBottom: 10 }}>
                          <Autocomplete
                              disablePortal
                              id="formAcademicaDtAte"
                              options={yearList}
                              renderInput={(params) => <TextField
                                {...params} 
                                InputLabelProps={{
                                  style: { fontWeight: 'bold' },
                                }}
                                placeholder="Quando terminou o curso"
                                label="Até:"
                                variant="outlined"
                                error={loginError.success}
                                helperText={loginError.success ? loginError.message : ""}
                                fullWidth
                              />}
                              onChange={(e) => {
                                console.log(dados);
                                onChangeFormacao(e, form?.id)
                              }}
                              onBlur={(e) => {
                                onChangeFormacao(e, form?.id)
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      )

                    })}
                  </div>
                  <br />

                  <div style={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', flexBasis: window.innerWidth > 600 ? '45%' : '100%', flexWrap: 'wrap' }}>
                    <div style={{ width: window.innerWidth > 600 ? '45%' : '100%', marginBottom: 10 }}>
                      <TextField
                        InputLabelProps={{
                          style: { fontWeight: 'bold' },
                        }}
                        error={loginError.success}
                        helperText={loginError.success ? loginError.message : ""}
                        type="text"
                        fullWidth
                        id="cep"
                        label="CEP"
                        variant="outlined"
                        onBlur={(e) => {
                          fetch(`https://viacep.com.br/ws/${e.target.value?.replace(/\D/g, "")}/json/`)
                            .then(res => res.json())
                            .then(res => {
                              let dt = dados;
                              dt['uf'] = res?.uf ?? "";
                              dt['bairro'] = res?.bairro ?? "";
                              dt['cidade'] = res?.localidade ?? "";
                              dt['logradouro'] = res?.logradouro ?? "";
                              setDados(dt);
                              setBairro(res?.bairro ?? "");
                              setCity(res?.localidade ?? "");
                              setUF(res?.uf ?? "");
                              setLogradouro(res?.logradouro ?? "");
                            })
                        }}
                        onChange={(e) => {
                          let dt = dados;
                          let val = e.target.value.replace(/\D/g, "");

                          if (val.length > 8) {
                            return false;
                          }

                          dt["cep"] = val; ///.replace(/\D/g, "");
                          setDados(dt);
                          setCEP(toCep(e.target.value))
                        }}
                        value={toCep(cep)}
                      />
                    </div>
                    <div style={{ width: window.innerWidth > 600 ? '45%' : '100%', marginBottom: 10 }}>
                      <TextField
                        InputLabelProps={{
                          style: { fontWeight: 'bold' },
                        }}
                        error={errors.find(err => err?.field == "logradouro")}
                        helperText={"* Obrigatório"}
                        type="text"
                        fullWidth
                        id="logradouro"
                        label="Logradouro"
                        variant="outlined"
                        value={logradouro}
                        //onBlur={onChange}
                        onChange={(e) => {
                          setLogradouro(e?.target?.value)
                          onChange(e)
                        }}
                      />
                    </div>
                  </div>
                  <br />

                  <div style={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', flexBasis: window.innerWidth > 600 ? '45%' : '100%', flexWrap: 'wrap' }}>
                    <div style={{ width: window.innerWidth > 600 ? '45%' : '100%', marginBottom: 10 }}>
                      <TextField
                        InputLabelProps={{
                          style: { fontWeight: 'bold' },
                        }}
                        error={errors.find(err => err?.field == "numero")}
                        helperText={"* Obrigatório"}
                        type="text"
                        fullWidth
                        id="numero"
                        label="Numero"
                        variant="outlined"
                        onBlur={onChange}
                        onChange={onChange}
                      />
                    </div>
                    <div style={{ width: window.innerWidth > 600 ? '45%' : '100%', marginBottom: 10 }}>
                      <TextField
                        InputLabelProps={{
                          style: { fontWeight: 'bold' },
                        }}
                        error={errors.find(err => err?.field == "bairro")}
                        helperText={"* Obrigatório"}
                        type="text"
                        fullWidth
                        id="bairro"
                        label="Bairro"
                        variant="outlined"
                        value={bairro}
                        onBlur={(e) => {
                          setBairro(e?.target?.value)
                          onChange(e)
                        }}
                        onChange={(e) => {
                          setBairro(e?.target?.value)
                          onChange(e)
                        }}
                      />
                    </div>
                  </div>
                  <br />

                  <div style={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', flexBasis: window.innerWidth > 600 ? '45%' : '100%', flexWrap: 'wrap' }}>
                    <div style={{ width: window.innerWidth > 600 ? '45%' : '100%', marginBottom: 10 }}>
                      <TextField
                        InputLabelProps={{
                          style: { fontWeight: 'bold' },
                        }}
                        error={errors.find(err => err?.field == "cidade")}
                        helperText={"* Obrigatório"}
                        type="text"
                        fullWidth
                        id="cidade"
                        label="Cidade"
                        variant="outlined"
                        value={city}
                        onBlur={(e) => {
                          setCity(e?.target?.value)
                          onChange(e)
                        }}
                        onChange={(e) => {
                          setCity(e?.target?.value)
                          onChange(e)
                        }}
                      />
                    </div>
                    <div style={{ width: window.innerWidth > 600 ? '45%' : '100%', marginBottom: 10 }}>


                      <View style={{ width: '100%' }}>
                        <InputLabel style={{ fontSize: 12, marginBottom: 5, fontWeight: 'bold' }} id="uf-select-label">
                          Estado:
                        </InputLabel>

                        <FormControl sx={{ m: 1 }} size="small">
                          <Select
                            size={'small'}
                            labelId="uf-select-label"
                            id="uf"
                            name="uf"
                            value={uf}
                            label="Selecione um ou mais Idiomas de atendimento"
                            error={errors.find(err => err?.field == "uf")}
                            helperText={"* Obrigatório"}
                            onChange={(e) => {
                              setUF(e?.target?.value)
                              onChange(e)
                            }}
                          >
                            {([
                              { nome: "Acre", sigla: "AC" },
                              { nome: "Alagoas", sigla: "AL" },
                              { nome: "Amapá", sigla: "AP" },
                              { nome: "Amazonas", sigla: "AM" },
                              { nome: "Bahia", sigla: "BA" },
                              { nome: "Ceará", sigla: "CE" },
                              { nome: "Distrito Federal", sigla: "DF" },
                              { nome: "Espírito Santo", sigla: "ES" },
                              { nome: "Goiás", sigla: "GO" },
                              { nome: "Maranhão", sigla: "MA" },
                              { nome: "Mato Grosso", sigla: "MT" },
                              { nome: "Mato Grosso do Sul", sigla: "MS" },
                              { nome: "Minas Gerais", sigla: "MG" },
                              { nome: "Pará", sigla: "PA" },
                              { nome: "Paraíba", sigla: "PB" },
                              { nome: "Paraná", sigla: "PR" },
                              { nome: "Pernambuco", sigla: "PE" },
                              { nome: "Piauí", sigla: "PI" },
                              { nome: "Rio de Janeiro", sigla: "RJ" },
                              { nome: "Rio Grande do Norte", sigla: "RN" },
                              { nome: "Rio Grande do Sul", sigla: "RS" },
                              { nome: "Rondônia", sigla: "RO" },
                              { nome: "Roraima", sigla: "RR" },
                              { nome: "Santa Catarina", sigla: "SC" },
                              { nome: "São Paulo", sigla: "SP" },
                              { nome: "Sergipe", sigla: "SE" },
                              { nome: "Tocantins", sigla: "TO" }

                            ] ?? []).map((item) => (
                              <MenuItem
                                key={`indicacao-${item?.nome}`}
                                value={item?.sigla}
                              >
                                {item?.sigla} - {item?.nome}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </View>
                    </div>
                  </div>
                  <br />
                  <div style={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', flexBasis: window.innerWidth > 600 ? '45%' : '100%', flexWrap: 'wrap' }}>
                    <div style={{ width: window.innerWidth > 600 ? '45%' : '100%', marginBottom: 10 }}>
                      <TextField
                        InputLabelProps={{
                          style: { fontWeight: 'bold' },
                        }}
                        type="text"
                        fullWidth
                        id="complemento"
                        label="Complemento"
                        variant="outlined"
                        onBlur={onChange}
                        onChange={onChange}
                      />
                    </div>
                  </div>
                  <br />

                  <InputLabel style={{ marginBottom: 5, fontWeight: 'bold' }} id="indicacao-select-label">
                    Demandas atendidas pelo psicólogo:
                  </InputLabel>
                  <InputLabel style={{ fontSize: 12, marginBottom: 5 }}>
                    As demandas atendidas pelo(a) psicólogo(a), são exibidas para os pacientes na página de marcaçao de consultas.
                    <br />Ex. Estresse, depressão ou outros
                  </InputLabel>
                  <br />
                  <div style={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', flexBasis: window.innerWidth > 600 ? '45%' : '100%', flexWrap: 'wrap' }}>
                    <div style={{ width: window.innerWidth > 600 ? '45%' : '100%', marginBottom: 10 }}>
                      <TextField
                        InputLabelProps={{
                          style: { fontWeight: 'bold' },
                        }}
                        error={loginError.success}
                        helperText={loginError.success ? loginError.message : ""}
                        type="text"
                        fullWidth
                        id="tagsTratamento"
                        label="Digite o nome da Demanda:"
                        variant="outlined"
                        onChange={onChange}
                        onBlur={onChange}
                      />
                    </div>
                  </div>
                  <br />


                </div>

                <br />

              </CardContent>

              <CardActions style={{ flexDirection: "column" }}>
                <div
                  style={{
                    marginBottom: 10,
                    marginLeft: 10,
                    alignSelf: "start",
                    cursor: "pointer",
                    color: "#025ea2",
                  }}
                >
                  <div style={{ marginTop: 10 }}>

                  </div>
                </div>
                <br />
                <ReCAPTCHA
                  sitekey={BRANDING?.recaptcha?.secret_key}
                  onChange={setCaptcha} />
                <br />
                {loading == false ? (
                  <Button
                    variant="contained"
                    size="large"
                    fullWidth
                    onClick={registerHandler}
                    disabled={captcha == false}
                    color="primary"
                    disableElevation
                    //type="submit"
                    //form="registerForm"
                    style={{
                      marginLeft: 0,
                      //background: 'linear-gradient(45deg, #025ea2 30%, #0086e8 90%)',
                    }}
                  >
                    {"Cadastrar"}
                  </Button>
                ) : (
                  <CircularProgress style={{ margin: "auto" }} />
                )}
              </CardActions>
            </Card>
          </div>
        </div>

      </form>
    </div>
  );
}
const mapStateToProps = (store) => ({
  aDialog: store.authReducer.authDialog,
  data: store.authReducer.data,
  loading: store.appReducer.loading,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { setProfileDialog, setLoading, authDialog, setAuth, JWT_Decode },
    dispatch
  );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Register));
