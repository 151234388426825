import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
//
import Colors from "../../commons/Colors";
//

import { setSnackbar, setTimer, setProfileDialog } from "../../actions/appActions";
import { Button, Card, CardActions, CardContent, Icon, InputLabel, List, ListItem, ListItemText, TablePagination } from "@material-ui/core";
import { StyleSheet, TouchableOpacity } from "react-native";
import HomeForm from "./components/form";
import { Image } from "@material-ui/icons";
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';

import { BRANDING } from "../../../branding";
import { GetHandler, PostHandler } from "../../handlers";
import LCardGrid from '../../components/List/cardgrid'
import moment from "moment";

const localizedTextsMap = {
  MuiTablePagination: {
    labelDisplayedRows: ({ from, to, count }) =>
      `${from} - ${to} de ${count}`,
  },
  columnMenuUnsort: "Não classificado",
  columnMenuSortAsc: "Classificar por ordem crescente",
  columnMenuSortDesc: "Classificar por ordem decrescente",
  columnMenuFilter: "Filtro",
  columnMenuHideColumn: "Ocultar",
  columnMenuShowColumns: "Mostrar colunas",
  columnManage: 'Gerenciar',
  noRow: 'Não há registros'
};
class Dependents extends Component {
  state = {
    data: [],
    selecteds: []
  };

  setResultScreen(visible) {
    this.setState({ resultScreen: visible });
  }

  async getData() {
    let info = JSON.parse(localStorage.getItem("user_info") ?? {});

    const request = await GetHandler(
      `pacientes/${info?.id}/dependentes`, {
      perfilId: BRANDING?.perfilId
    }
    );

    if (
      request &&
      Array.isArray(request?.data?.data) &&
      request?.data?.data?.length >= 0
    ) {
      let data = this.state.data;
      data = [...request?.data?.data];
      this.setState({
        data,
      });

    }
  }
  async componentDidMount() {
    let token = JSON.parse(localStorage.getItem("auth_token") ?? {});
    const auth = JSON.parse(localStorage.getItem("user_info"));
    if (!auth?.id) {
      window.location.href = "/";
    } else {
      await this.getData();
    }

  }
  render() {
    const columns = [
      {
        field: 'nome',
        headerName: 'Nome Completo',
        headerClassName: 'col-header',
        sortable: false,
        width: 350,
        flex: 1,
        valueGetter: (params) =>
          `${params.row.nome || ''} ${params.row.sobrenome || ''}`,
      },
      {
        field: 'filiacao', headerName: 'Filiação', headerClassName: 'col-header', flex: 1, width: 130, valueGetter: (params) =>
          `${params.row?.filiacao ?? ''}`
      },
      { field: 'cpf', headerName: 'CPF', headerClassName: 'col-header', flex: 1, width: 150 },
      {
        field: 'dataNascimento',
        headerName: 'Data de Nascimento',
        headerClassName: 'col-header',
        sortable: false,
        flex: 1,
        width: 160,
        valueGetter: (params) =>
          params?.row?.dataNascimento ? `${moment(params?.row?.dataNascimento)?.format('DD/MM/yyyy')}` : ""
      },
      { field: 'sexo', headerName: 'Sexo', headerClassName: 'col-header', flex: 1 },
      {
        field: 'sobrenome', headerName: 'Status', flex: 1, headerClassName: 'col-header', renderCell: (params) =>
          <b>{params?.row?.dadosAprovacao?.status?.nome ?? ''}</b>
      },
      {
        field: 'possuiAprovacao', headerName: 'Aprovação', headerClassName: 'col-header', flex: 1, width: 300, renderCell: (params) =>
        (<div style={{ zindex: 99 }}>
          <div>
            <div
              style={{ color: params.row?.dadosAprovacao?.status?.id == 2 ? '#0A1' : Colors.red, paddingHorizontal: 10, paddingVertical: 5, textAlign: window.innerWidth >= 680 ? 'center' : 'start', borderRadius: 5 }}
            >
              {params?.row?.dadosAprovacao?.status?.id == 2 ? "SIM" : "NÃO"}
            </div>
          </div>
        </div>)
      },

    ];

    const rows = this.state.data;

    return (
      <Fragment>

        <div style={{ height: 500, width: '100%' }}>
          <div style={{ marginTop: 25, marginBottom: 25, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
            <div style={{marginRight: 10}}>
              <Button
                variant={"contained"}
                color={'primary'}
                onClick={() => {
                  window.location.href = '/dependents/create'
                }}>
                Cadastrar
              </Button>
            </div>
            <div>
              {rows.filter(it => it.dadosAprovacao?.status?.id == 4).length > 0 ? (
                <Button
                title="Existem registros com a confirmação de código pendente."
                variant={"contained"}
                color={'primary'}
                style={{
                  backgroundColor: Colors.warning
                }}
                onClick={async () => {
                  let user = JSON.parse(localStorage.getItem("user_info"));
                  const sendCode = await PostHandler(`pacientes/431186/codigo/enviarCodigo?perfilId=${user?.perfilId}`, {}, this.props.navigation);
                  console.log('Código: ', sendCode?.data)
                  let from = [];
                  if (sendCode?.data?.data?.sms == true) {
                    from.push('SMS');
                  }

                  if (sendCode?.data?.data?.email == true) {
                    from.push('E-Mail');
                  }

                  if (sendCode?.data?.data?.whatsapp == true) {
                    from.push('WhatsApp');
                  }
                  this.props.setProfileDialog({ open: true, type: 'OTP', from, message: sendCode?.data?.message, onSave: async () => { console.log('APERTOU PRA SALVAR'); await this.getData() } })
                }}>
                Autorizar Pendentes
              </Button>
              ) :undefined}
              
            </div>
          </div>
          {window.innerWidth >= 680 ? (<DataGrid
            style={{ backgroundColor: '#fff' }}
            sx={{
              height: 300,
              width: '100%',
              '.col-header': {
                backgroundColor: '#e3ebf2',

              },
              '.MuiDataGrid-columnHeaderTitle': {
                fontWeight: 'bold'
              }
            }}
            localeText={localizedTextsMap}
            componentsProps={{
              pagination: {
                labelRowsPerPage: 'Registros por página '
              }
            }}
            disableColumnMenu={true}
            rows={rows}
            getRowId={(row) => `${row?.nome}_${row?.sobrenome}_${row?.cpf}}`}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
            }}
            pageSizeOptions={[5, 10, 25]}

            onRowSelectionModelChange={(ids) => {
              this.setState({ selecteds: ids });
            }}
          />) : (
            <div style={{ marginBottom: 20 }}>
              {!this.state.firstLoad &&
                <div style={{ height: 450, width: '100%' }}>
                  {rows.length == 0 ? (
                    <Card style={{ marginTop: 15 }}>
                      <CardContent> Não há registros</CardContent>
                    </Card>) : ('')
                  }
                  {rows.map((row, key) => {
                    return (
                      <Card key={`card-container-${key}`} style={{ marginTop: 15 }}>
                        <CardContent>
                          <List key={`list_field_${key}`} component="nav">
                            {Object.entries(row).map(field => {
                              let headerName = columns.find(column => column.field === field[0]);
                              if (headerName && headerName.field !== 'id') {
                                let value = headerName.value ?? headerName.valueFormatter ?? headerName.renderCell;
                                value = value == undefined ? field[1] : value(headerName.row == true ? { row } : { value: field[1] });
                                value = value == null ? " - " : value;
                                if (headerName.renderCell !== undefined) {
                                  value = headerName.renderCell({ value: field[1], row: row }, row);
                                  value = value == null ? " - " : value;
                                  return (
                                    <ListItem style={{ paddingTop: 0, paddingBottom: 0 }}>
                                      <ListItemText primary={`${headerName.headerName}`} secondary={value} />
                                    </ListItem>
                                  )
                                } else {
                                  return (
                                    <ListItem style={{ paddingTop: 0, paddingBottom: 0 }}>
                                      <ListItemText primary={`${headerName.headerName}`} secondary={`${value}`} />
                                    </ListItem>
                                  )
                                }
                              }
                            })}
                          </List>
                        </CardContent>
                        <CardActions style={{ justifyContent: 'center' }}>
                          {Object.entries(row).map(field => {
                            let headerName = columns.find(column => column.field === field[0]);
                            if (headerName && headerName.field == 'id') {
                              return headerName.renderCell({ value: field[1], row }, row);
                            }
                          })}
                        </CardActions>
                      </Card>
                    )
                  }
                  )}
                  {/*<Card style={{ marginTop: 15 }}>
                    <TablePagination
                      component="div"
                      //localetext={DEFAULT_LOCALE_TEXT}
                      count={this.state.data.total}
                      page={this.state.data.current_page - 1}
                      onChangePage={(e, params) => {
                        console.log(params)
                        let filters = Object.assign({}, this.state.filters, { page: params + 1, pageSize: 10 });
                        this.setState({ ...this.state, filters });
                        this.setPage(filters);
                      }}
                      labelDisplayedRows={({ from, to, count }) => {
                        return `${from}-${to} de ${count !== -1 ? count : to}`
                      }}
                      labelRowsPerPage={`Registros: `}
                      rowsPerPage={10}
                    />
                    </Card>*/}
                  <div style={{ marginBottom: 15 }}>
                  </div>
                </div>}
            </div>
          )}
        </div>

      </Fragment>
    );
  }
}
const mapStateToProps = (store) => ({
  session: store.authReducer.data,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setSnackbar, setTimer, setProfileDialog }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Dependents);

const styles = {
  colHeader: {
    backgroundColor: '#e3ebf2',
  },
  container: {
    paddingTop: 30,
    borderRadius: 0,
    backgroundColor: Colors.primary,
    minHeight: "100%",
  },
  secView: {
    flex: 1,
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    paddingRight: 15,
    maxHeight: 65,
    paddingBottom: 15,
    bottom: 0,
    backgroundColor: Colors.secondary,
    width: "100%",
  },
  logoContainer: {
    alignItems: "center",
    justifyContent: "center",
    padding: 30,
  },
  logo: {
    height: 150,
    width: 150,
    resizeMode: "contain",
  },
  inputDefaultStyle: {
    height: 40,
  },
};
