export class Credentials {
    password: '';
    email: '';
    perfil_id: 0;
    //paciente = '1';
    constructor(credentials : {email: '', password: '', perfil_id: 0}) {
        this.email = credentials.email;
        this.password = credentials.password;
        this.perfil_id = credentials.perfil_id;
    }
 
    toString(){
        return this.email + " " + this.password;
    }
}