import React from "react";
import AppRouter from "./src/router"
import { connect, Provider } from 'react-redux'
import store from './store'
import Footer from "./src/components/Layout/Footer";
import { View } from "react-native";
console.reportErrorsAsExceptions = false;
const App = () => (
  <>
    <Provider store={store}>
      <View style={{flexDirection: 'column', width: '100%'}}>
        <AppRouter />
        <Footer />
      </View>
    </Provider>

  </>

);

export default App;
