export const BRANDING = {
    perfilId: 3580,
    apiHost: "https://api.clinicas2-dev.simdoctor.com.br/v1/clinicas",
    recaptcha: {
        secret_key:"6Le_ASQTAAAAADEgmC1b5YUyFIhwztR_FJ7OMgv0"
    },
    internal: {
        email: "internoSimdoctor",
        password: "?AH%7Vd!nRBdjQRx",
        perfilId: 3580,
    },
    Colors: {
        primary: '#0a5e7c',
        secondary: '#0a5e7c',
        tertiary: '#3acc8a',
        quaternary: '#86fce2',
        quinary: '#0a5e7c',
        senary: '',
        white: '#FFFFFF',
        black: '#333333',
        grey: '#e7e7e7',
        red: '#f44336',
        alert: '#fbf5c4',
        warning: '#ffa500',
        border: '#e7e7e7'
    },
    titleHeader: "SIMDOCTOR",
    subtitleHeader: "Terapia comportamental online",
    filterLogo: {
        img: require("./assets/images/simdoctor/filter_logo.png") ?? "",
        width: 52
    },
    loginLogo: {
        img: require("./assets/images/simdoctor/logo_login.png") ?? "",
        height: 45
    },
    homeText: "Na plataforma SimDoctor já pode encontrar médicos a fazer consultas online. Você pode usar o filtro para especificar para encontrar o especialista certo para suas necessidades e se conectar com ele em apenas alguns cliques"
}