import {
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  TextField,
} from "@material-ui/core";
import Select from '@mui/material/Select';
import moment from "moment";
import React from "react";
import { View, Text } from "react-native-web";

import { AuthInternalHandler, GetHandler } from "../../../handlers";
import { toMoney } from "../../../handlers/money";
import Storage from "../../../handlers/storage";
import { Credentials } from "../../../models/Credentials";
import RangeSlider, { Slider } from "react-native-range-slider-expo";
import RangeSliderCustom from "./RangeSliderCustom";
import { Link } from "../../../../react-router";
import { BRANDING } from "../../../../branding";

////

import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import Chip from "@mui/material/Chip";
import { ScrollView } from "react-native-web";
import { ActivityIndicator } from "react-native";

const TagsInput = (props) => {
  const theme = useTheme();
  const [tags, setTags] = React.useState(typeof props?.value === 'string' ? props.value.split(',') : props?.value ?? []);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        //minWidth: 250,
      },
    },
  };

  const getStyles = (label, tags, theme) => {
    return {
      fontWeight:
        (props?.tags ?? []).map(tag => tag.label).indexOf(label) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  };

  const handleChange = (e) => {
    /*let ltags = tags;
    ltags.push(props.tags.find(t => t.label == e.target.value[0]));
    
    console.log(e.target.value[0],ltags)*/
    const {
      target: { value },
    } = e;
    setTags(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
    props.setTags(typeof value !== 'string' ? value.join(',') : value);
    props.onChange();
  };
  return (
    <div>
      <FormControl sx={{ m: 1, width: 250 }}>
        <Select
          style={{ width: 250 }}
          labelId="select-formacao"
          displayEmpty
          id="formacao"
          multiple
          value={tags}
          onChange={handleChange}
          input={
            <OutlinedInput
              id="select-formacao"
              placeholder={'Demanda do cliente'}
              //label={props?.label}
              size="small"
            />
          }
          renderValue={(selected) => {
            console.log('selected', selected)
            return (
              <ScrollView nestedScrollEnabled={true} horizontal={true} sx={{ display: "flex", flexWrap: "nowrap", gap: 0.5 }}>
                {(selected ?? []).map((tag) => {
                  console.log('TAG', tag);
                  return (
                    <Chip size="small" key={tag} label={tag} style={{ marginEnd: 2 }} />
                  )
                })}
              </ScrollView>
            )
          }}
          MenuProps={MenuProps}
          inputProps={{ 'aria-label': 'Without label' }}
        >
          <MenuItem disabled value="">
            <em style={{ color: "#333" }}>{props?.label ?? ""}</em>
          </MenuItem>
          {(props?.tags ?? []).map((tag) => (
            <MenuItem
              key={tag?.id}
              value={tag?.label}
              style={getStyles(
                tag,
                tags,
                theme
              )}
            >
              <Checkbox checked={tags.indexOf(tag?.label) > -1} />
              <ListItemText primary={tag?.label} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
export default class HomeForm extends React.Component {
  state = {
    first: true,
    allFilters: false,
    resultScreen: false,
    filtersData: [],
    skills: [],
    tags: [],
    academic: [],
    callGroups: [],
    minValue: 50,
    maxValue: 350,
    filter: {
      totalConsultasAtendidas: true,
      nome: "",
      sexo: [],
      tipoAtendimento: "video",
      valorConsulta: 0,
      valorConsultaMax: 500,
      especialidade: "todos",
      tags: undefined,
      grupoAtendimentoId: [],
      idiomaId: [],
      nomeFormacao: ["todos"],
    },
  };

  getResults() {
    if (this.state?.filter?.tipoAtendimento != "") {
      let filter = this.state.filter;
      filter.nomeFormacao = filter.nomeFormacao.includes("todos")
        ? [""]
        : filter.nomeFormacao;
      filter.especialidade =
        filter.especialidade == "todos" ? "" : filter.especialidade;
      this.props.router.push({
        pathname: "/search",
        filter,
      });
      //this.setState({ resultScreen: true });
    } else {
      Alert.alert(
        "Campo Obrigatório",
        "o Campo Tipo de Atendimento é Obrigatório"
      );
    }
  }

  async getData(params = {}) {
    let step = params?.step ?? 0;
    this.setState({ first: true });
    let token = await Storage.get("auth_token");

    if (!token?.token) {
      await AuthInternalHandler(
        new Credentials(BRANDING?.internal)
      );
    }

    let filter1 = this.state.filter;

    filter1.nomeFormacao = filter1?.nomeFormacao.includes("todos")
      ? [""]
      : filter1.nomeFormacao;
    filter1.especialidade =
      filter1?.especialidade == "todos" ? "" : filter1.especialidade;
    if (
      filter1?.valorConsultaMax.length > 0 ||
      filter1?.valorConsulta.length > 0
    ) {
      filter1.valorConsultaMax = toMoney(
        filter1.valorConsultaMax,
        true
      ).replace(",", ".");
      filter1.valorConsulta = toMoney(filter1.valorConsulta, true).replace(
        ",",
        "."
      );
    }

    filter1.especialidade = filter1.especialidade ?? "";
    let session = JSON.parse(localStorage.getItem("user_info"));
    let filtersData = await GetHandler(
      `doutores/filtros`, // ALTERAR EM PROD
      {
        ...filter1,
        favoritoPacienteId: session?.id ? session?.id : "",
        perfilId: BRANDING?.perfilId,
        withDoctors: true,
        tipoAtendimento: this.state.filter.tipoAtendimento,
      },
      this.props.navigation
    );
    filtersData = filtersData?.data?.data ?? {};
    let filter = this.state.filter;
    let precoPresencial = filtersData?.precoPresencial;
    let precoVideo = filtersData?.precoVideo;

    /* {
      totalConsultasAtendidas: true,
      nome: "",
      sexo: [],
      tipoAtendimento: "video",
      valorConsulta: "0.00",
      valorConsultaMax: "350.00",
      especialidade: "todos",
      grupoAtendimentoId: [],
      idiomaId: [],
      nomeFormacao: ["todos"],
    };*/
    let minValue = 90;
    let maxValue = 350;

    if (precoPresencial && precoPresencial?.max == precoPresencial?.min) {
      precoPresencial.min =
        parseInt(filtersData?.precoPresencial?.min ?? 50) - 10;
      precoPresencial.max =
        parseInt(filtersData?.precoPresencial?.max ?? 350) + 10;
      filtersData.precoPresencial = precoPresencial;

      minValue = precoPresencial?.min;
      maxValue = precoPresencial?.max;
    }

    if (precoVideo && precoVideo?.max == precoVideo?.min) {
      precoVideo.min = parseInt(filtersData?.precoVideo?.min ?? 50) - 10;
      precoVideo.max = parseInt(filtersData?.precoVideo?.max ?? 350) + 10;
      filtersData.precoVideo = precoVideo;
      minValue = precoVideo?.min;
      maxValue = precoVideo?.max;
    }

    minValue =
      filtersData[
        this.state.filter.tipoAtendimento == "video"
          ? "precoVideo"
          : "precoPresencial"
      ]?.min ?? 50.0; //filtersData?.precoVideo?.min
    maxValue =
      filtersData[
        this.state.filter.tipoAtendimento == "video"
          ? "precoVideo"
          : "precoPresencial"
      ]?.max ?? 350.0;
    if (
      params?.reloadPrice == undefined ||
      params?.reloadPrice == true ||
      step < 4
    ) {
      filter.valorConsulta =
        filter.valorConsulta < toMoney(minValue, true) ||
          filter.valorConsulta > toMoney(maxValue, true)
          ? toMoney(minValue, true)
          : filter.valorConsulta;
      filter.valorConsultaMax =
        filter.valorConsultaMax < toMoney(minValue, true) ||
          filter.valorConsultaMax > toMoney(maxValue, true)
          ? toMoney(maxValue, true)
          : filter.valorConsultaMax;
    }

    this.setState({
      first: false,
      filtersData,
      skills: (filtersData?.especialidades ?? []).map((item, key) => {
        return { value: item.nome, label: item.nome };
      }),
      minValue: filter.valorConsulta,
      maxValue: filter.valorConsultaMax,
    });

    if (filtersData?.tags?.length >= 0 && step <= 1) {
      filter1.valorConsultaMax =
        maxValue == null ? "0,00" : maxValue + ""?.replace(".", ",");
      this.setState({
        filtersData,
        minValue,
        maxValue,
        filter: filter1,
        tags: (filtersData?.tags ?? []).map((item, key) => {
          return { id: item.nome, label: item.nome };
        }),
      });
    }

    if (filtersData?.formacaoAcademica?.length >= 0 && step <= 2) {
      filter1.valorConsultaMax =
        maxValue == null ? "0,00" : maxValue + ""?.replace(".", ",");
      this.setState({
        minValue,
        maxValue,
        academic: (filtersData?.formacaoAcademica ?? []).map((item, key) => {
          return { value: item.nome, label: item.nome };
        }),
      });
    }
    if (filtersData?.sexo?.length >= 0) {
      this.setState({
        sexo: (filtersData?.sexo ?? []).map((item, key) => {
          return {
            value: item.cod,
            label: item.nome,
            disabled: item?.disabled,
          };
        }),
      });
    }

    if (filtersData?.idiomas?.length >= 0) {
      this.setState({
        idiomas: (filtersData?.idiomas ?? []).map((item, key) => {
          return { value: item.id, label: item.nome, disabled: item?.disabled };
        }),
      });
    }

    if (filtersData?.grupoAtendimento?.length >= 0) {
      this.setState({
        filtersData,
        callGroups: (filtersData?.grupoAtendimento ?? []).map((item, key) => {
          return { value: item.id, label: item.nome, disabled: item?.disabled };
        }),
      });
    }
  }

  async componentDidMount() {
    this.setState({
      filters: {
        totalConsultasAtendidas: true,
        nome: "",
        sexo: [],
        tipoAtendimento: "video",
        valorConsulta: undefined,
        valorConsultaMax: undefined,
        especialidade: "todos",
        tags: "",
        grupoAtendimentoId: [],
        idiomaId: [],
        nomeFormacao: ["todos"],
      },
    });
    await this.getData();
    const ss = await Storage.get("save_session");
    console.log("save_session1: ", ss, moment());
    if (ss?.active) {
      const dateIsAfter = moment().isAfter(moment(ss?.dateTime));
      if (dateIsAfter) {
        await Storage.set("user_info", null);
        await Storage.set("save_session", null);
      }
    }
  }

  render() {
    return (
      <Card>
        <CardContent>
          <View>
            <InputLabel style={{ fontSize: 18, textAlign: 'center', marginBottom: 15 }} id="select-filter">
              Selecione seu filtro
            </InputLabel>
          </View>
          <View
            style={{
              marginTop: 5,
              flexDirection: window.innerWidth >= 680 ? "row" : undefined,
            }}
          >
            <View style={{ marginEnd: 10 }}>
              <InputLabel style={{ fontSize: 12, marginBottom: 5 }} id="modalidade">
                Escolha a Modalidade
              </InputLabel>
              <View
                style={{
                  width: window.innerWidth >= 680 ? "33%" : "100%",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                }}
              >
                <View style={{ marginEnd: 10 }}>
                  <Button
                    variant={
                      this.state.filter.tipoAtendimento == "video"
                        ? "contained"
                        : "outlined"
                    }
                    color="primary"
                    onClick={() => {
                      let filter = this.state.filter;
                      filter.tipoAtendimento = "video";
                      filter.valorConsultaMax = "";
                      filter.valorConsulta = "";
                      filter.tags = [];
                      this.setState({ ...this.state, filter });
                      this.getData({ step: 1 });
                    }}
                  >
                    Online
                  </Button>
                </View>
                <View>
                  <Button
                    onClick={() => {
                      let filter = this.state.filter;
                      filter.tipoAtendimento = "presencial";
                      filter.valorConsultaMax = "";
                      filter.valorConsulta = "";
                      filter.tags = [];
                      this.setState({ ...this.state, filter, tags: [] });

                      this.getData({ step: 1 });
                    }}
                    variant={
                      this.state.filter.tipoAtendimento == "presencial"
                        ? "contained"
                        : "outlined"
                    }
                    color="primary"
                  >
                    Presencial
                  </Button>
                </View>
              </View>
            </View>
            <View
              style={{
                width: window.innerWidth >= 680 ? "25%" : "95%",
                marginEnd: 15,
                marginTop: window.innerWidth >= 680 ? undefined : 10,
              }}
            >
              <InputLabel style={{ fontSize: 12, marginBottom: 5 }} id="especialidade-select-label">
                Especialidade
              </InputLabel>
              <FormControl sx={{ m: 1 }} size="small">

                <Select
                  size={'small'}
                  labelId="especialidade-select-label"
                  id="especialidade-select"
                  value={
                    (this.state.skills ?? []).find(
                      (item) => item.value == this.state?.filter?.especialidade
                    )?.value ?? "todos"
                  }
                  label="Especialidade"
                  onChange={(e) => {
                    let filter = this.state.filter;
                    filter.especialidade = (this.state.skills ?? []).find(
                      (item) => item.value == e.target.value
                    )?.value;
                    filter.nomeFormacao[0] = "todos";
                    this.setState({ filter });
                    this.getData({ step: 2 });
                  }}
                >
                  <MenuItem key={`especialidade-selection`} value={"todos"}>
                    {`Todos`}
                  </MenuItem>
                  {(this.state.skills ?? []).map((item) => (
                    <MenuItem
                      key={`especialidade-${item.value}`}
                      value={item.value}
                    >
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </View>
            <View
              style={{
                width: window.innerWidth >= 680 ? "41%" : "100%",
                marginEnd: 15,
              }}
            >
              <InputLabel style={{ fontSize: 12, marginBottom: 5 }}>Demanda do cliente</InputLabel>
              {this.state.first == false ? (
                <TagsInput
                  label={'Demanda do cliente'}
                  value={this.state?.filter?.tags}
                  tags={(this.state?.tags ?? [])}
                  setTags={(tags) => {
                    let filter = this?.state?.filter;
                    filter.tags = tags
                    this.setState({ ...this.state, filter });
                  }}
                  onChange={() => {
                    this.getData({ step: 2 });
                  }}
                />
              ) : <ActivityIndicator />}
              {/*<View
                style={{
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  marginEnd: 10,
                  marginTop: 10,
                  flexWrap: "wrap",
                  width: window.innerWidth >= 680 ? "80%" : 300,
                }}
              >
              </View>*/}
            </View>
          </View>
          <InputLabel style={{ marginTop: 25 }} id="especialidade-select-label">
            Valor:
          </InputLabel>
          <View>
            <RangeSlider
              step={10}
              showRangeLabels={false}
              min={parseFloat(
                this.state?.filtersData[
                  this.state.filter?.tipoAtendimento == "video"
                    ? "precoVideo"
                    : "precoPresencial"
                ]?.min ?? 50
              )}
              max={parseFloat(
                this.state?.filtersData[
                  this.state.filter?.tipoAtendimento == "video"
                    ? "precoVideo"
                    : "precoPresencial"
                ]?.max ?? 350.0
              )}
              fromValueOnChange={(value) => {
                let filter = this.state.filter;
                let min = parseFloat(
                  this.state?.filtersData[
                    this.state.filter?.tipoAtendimento == "video"
                      ? "precoVideo"
                      : "precoPresencial"
                  ]?.min ?? "50.0"
                );

                filter.valorConsulta = toMoney(value, true);
                this.setState({
                  ...this.state,
                  filter,
                  minValue: toMoney(value, true),
                });
                this.getData({ reloadPrice: false, step: 4 });
              }}
              toValueOnChange={(value) => {
                let filter = this.state.filter;
                let max = parseFloat(
                  this.state?.filtersData[
                    this.state.filter?.tipoAtendimento == "video"
                      ? "precoVideo"
                      : "precoPresencial"
                  ]?.max ?? "500.0"
                );
                filter.valorConsultaMax = value > max ? max : value; //toMoney(value, true);
                this.setState({
                  ...this.state,
                  filter,
                  maxValue: toMoney(value > max ? max : value, true),
                });
                this.getData({ reloadPrice: false, step: 4 });
              }}
              initialFromValue={50}
            />
          </View>
          <View
            style={{
              marginTop: 5,
              flexDirection: "row",
              justifyContent: "space-between",
              marginHorizontal: 10,
              marginEnd: 20,
            }}
          >
            <View style={{ marginEnd: window.innerWidth >= 680 ? 25 : 40 }}>
              <TextField
                disabled
                size="small"
                style={{ width: window.innerWidth >= 680 ? 160 : 110 }}
                id="minPrice"
                label="Mínimo"
                variant="outlined"
                value={toMoney(this.state.minValue)}
                onChange={({ e }) => {
                  let filter = this.state.filter;
                  let minValue = e.target.value;
                  let min = parseFloat(
                    this.state?.filtersData[
                      this.state.filter?.tipoAtendimento == "video"
                        ? "precoVideo"
                        : "precoPresencial"
                    ]?.min ?? "50.0"
                  );
                  filter.valorConsulta = toMoney(
                    minValue < min ? min : minValue,
                    true
                  );
                  this.setState({
                    ...this.state,
                    filter,
                    minOK: true,
                    minValue: minValue < min ? min : minValue,
                  });
                }}
              />
            </View>
            <View
              style={{ width: window.innerWidth >= 680 ? undefined : "80%" }}
            >
              <TextField
                disabled
                size="small"
                style={{ width: window.innerWidth >= 680 ? 160 : 110 }}
                id="maxPrice"
                label="Máximo"
                variant="outlined"
                value={toMoney(this.state.maxValue)}
                onChange={(e) => {
                  let filter = this.state.filter;
                  let maxValue = e.target.value;
                  let max = parseFloat(
                    this.state?.filtersData[
                      this.state.filter?.tipoAtendimento == "video"
                        ? "precoVideo"
                        : "precoPresencial"
                    ]?.max ?? "350.0"
                  );
                  filter.valorConsultaMax = toMoney(
                    maxValue > max ? max : maxValue,
                    true
                  );
                  this.setState({
                    ...this.state,
                    filter,
                    maxValue: maxValue > max ? max : maxValue,
                  });
                }}
              />
            </View>
          </View>
          <View
            style={{
              width: window.innerWidth >= 680 ? undefined : "100%",
              flexDirection: window.innerWidth >= 680 ? "row" : "column",
              justifyContent: window.innerWidth >= 680 ? "space-between" : undefined,
            }}
          >
            <View>
              <InputLabel
                style={{ marginTop: 25 }}
                id="especialidade-select-label"
              >
                Gênero:
              </InputLabel>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  marginTop: 10,
                  flexWrap: "wrap",
                  width: window.innerWidth >= 680 ? "80%" : 300,
                }}
              >
                {(
                  this.state?.sexo ?? [
                    { id: "M", label: "Masculino" },
                    { id: "F", label: "Feminino" },
                    { id: "O", label: "Outros" },
                  ]
                ).map((item) => {
                  let active = this.state.filter.sexo.includes(item.value);
                  return (
                    <View style={{ marginEnd: 10, marginBottom: 10 }}>
                      <Button
                        variant={active ? "contained" : "outlined"}
                        color="primary"
                        disabled={item?.disabled == true}
                        onClick={() => {
                          let filter = this.state.filter;
                          if (this.state.filter.sexo.includes(item.value)) {
                            filter.sexo.splice(
                              filter.sexo.indexOf(item.value),
                              1
                            );
                          } else {
                            filter.sexo.push(item.value);
                          }

                          this.setState({
                            ...this.state,
                            filter,
                          });
                          //if(this.state.first == false)
                          this.getData({ step: 3 });
                        }}
                      >
                        {item.label}
                      </Button>
                    </View>
                  );
                })}
              </View>

              <View>
                <InputLabel
                  style={{ marginTop: 25 }}
                  id="especialidade-select-label"
                >
                  Idioma:
                </InputLabel>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    marginEnd: 10,
                    marginTop: 10,
                    flexWrap: "wrap",
                    width: window.innerWidth >= 680 ? "80%" : 300,
                  }}
                >
                  {(
                    this.state?.idiomas ?? [
                      { id: "1", label: "Português" },
                      { id: "2", label: "Inglês" },
                      { id: "3", label: "Alemão" },
                    ]
                  ).map((item) => {
                    let active = this.state.filter.idiomaId.includes(
                      item.value
                    );
                    return (
                      <View style={{ marginEnd: 10, marginBottom: 10 }}>
                        <Button
                          variant={active ? "contained" : "outlined"}
                          color="primary"
                          disabled={item?.disabled}
                          onClick={() => {
                            console.log(
                              item,
                              this.state.filter.idiomaId,
                              active
                            );
                            let filter = this.state.filter;
                            if (
                              this.state.filter.idiomaId.includes(item.value)
                            ) {
                              filter.idiomaId.splice(
                                filter.idiomaId.indexOf(item.value),
                                1
                              );
                            } else {
                              filter.idiomaId.push(item.value);
                            }

                            this.setState({
                              ...this.state,
                              filter,
                            });
                            //if(this.state.first == false)
                            this.getData({ step: 4 });
                          }}
                        >
                          {item.label}
                        </Button>
                      </View>
                    );
                  })}
                </View>
              </View>
            </View>

            <View style={{ width: "45%" }}>
              <InputLabel
                style={{ marginTop: 25 }}
                id="especialidade-select-label"
              >
                Atendimento a :
              </InputLabel>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  marginEnd: 10,
                  marginTop: 10,
                  flexWrap: "wrap",
                  width: window.innerWidth >= 680 ? "80%" : 300,
                }}
              >
                {(
                  this.state?.callGroups ?? [
                    { id: "1", label: "Crianças" },
                    { id: "2", label: "Adultos" },
                    { id: "3", label: "Jovens" },
                    { id: "4", label: "Idosos" },
                  ]
                ).map((item) => {
                  let active = this.state.filter.grupoAtendimentoId.includes(
                    item.value
                  );
                  return (
                    <View style={{ marginEnd: 10, marginBottom: 10 }}>
                      <Button
                        variant={active ? "contained" : "outlined"}
                        color="primary"
                        disabled={item?.disabled}
                        onClick={() => {
                          let filter = this.state.filter;
                          if (
                            this.state.filter.grupoAtendimentoId.includes(
                              item.value
                            )
                          ) {
                            filter.grupoAtendimentoId.splice(
                              filter.grupoAtendimentoId.indexOf(item.value),
                              1
                            );
                          } else {
                            filter.grupoAtendimentoId.push(item.value);
                          }

                          this.setState({
                            ...this.state,
                            filter,
                          });
                          //if(this.state.first == false)
                          //this.getData();
                        }}
                      >
                        {item.label}
                      </Button>
                    </View>
                  );
                })}
              </View>
            </View>
          </View>

          <View
            style={{ flexDirection: "row", justifyContent: "space-between" }}
          >
            <View></View>
            <View style={{ top: 0 }}>
              {(this.state?.filtersData?.grupoAtendimento ?? [])?.filter(
                (i) => i.disabled == false
              )?.length == 0 &&
                (this.state?.filtersData?.idiomas ?? [])?.filter(
                  (i) => i.disabled == false
                )?.length == 0 &&
                (this.state?.filtersData?.sexo ?? [])?.filter(
                  (i) => i.disabled == false
                )?.length == 0 ? (
                <View style={{ flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center' }}>
                  {this.state.first == false &&
                    <View style={{ marginRight: 10 }}>
                      <InputLabel style={{ maxWidth: 300, color: '#f22' }}>
                        Não há resultados para esta busca, por favor revise os filtros selecionados
                      </InputLabel>
                    </View>
                  }
                  <View>
                    <Button disabled={true} variant={"outlined"} color="primary">
                      Buscar
                    </Button>
                  </View>
                </View>
              ) : (
                <Link
                  style={{ textDecoration: "none" }}
                  to={{
                    pathname: "/search",
                    state: this.state.filter,
                  }}
                >
                  <Button variant={"contained"} color="primary">
                    Buscar
                  </Button>
                </Link>
              )}
            </View>
          </View>
        </CardContent>
      </Card>
    );
  }
}
