import React, { Component } from "react";
//import {  Button,  FAB, } from "react-native-paper";
import { Text, View, FAB } from "react-native-web";
import moment from "moment";
import "moment/locale/pt-br";

import Colors from "../../../commons/Colors";
import { Button, IconButton, InputLabel, TextField } from "@material-ui/core";
import { ArrowLeft, ArrowRight } from "@material-ui/icons";

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TouchableHighlight } from "react-native";
import { TextInput } from "react-native-gesture-handler";


export default class CalendarHeader extends Component {
  state = {
    openDateDialog: false,
    data: this.props.data,
    currentTime: "00:00",
  };
  componentDidMount() {
    this.setState({ ...this.state, currentTime: moment().format("HH:mm") });
    setInterval(
      function () {
        this.setState({ ...this.state, currentTime: moment().format("HH:mm") });
      }.bind(this),
      60000
    );
  }

  render() {
    return (
      <View key={"Header" + moment().milliseconds()}>
        <View style={this.styles.header}>
          <IconButton
            style={this.styles.fab}
            variant={"contained"}
            small
            icon="arrow-left"
            onClick={() => {
              let dataStart = this.props.dataStart;
              if (
                dataStart.format("yyyy-MM-DD") == moment().format("yyyy-MM-DD")
              ) {
                return false;
              }
              dataStart.subtract(1, !this.props.month ? "months" : "days");
              this.props.setDate(dataStart);
              this.props.getData(dataStart);
            }}
          >
            <ArrowLeft />
          </IconButton>
          {!this.props.month ? (
            <View style={{ justifyContent: "center" }}>
              <Text style={{ textAlign: "center" }}>
                {this.props.dataStart.format("yyyy")}
              </Text>
              <Text
                style={{
                  color: Colors.tertiary,
                  fontWeight: "bold",
                  textAlign: "center",
                  fontSize: 22,
                }}
              >{`${this.props.dataStart
                .format("MMMM")
                .charAt(0)
                .toUpperCase()}${this.props.dataStart
                .format("MMMM")
                .slice(1)}`}</Text>
            </View>
          ) : (
            <View style={{ justifyContent: "center" }}>
              <Text style={{ textAlign: "center" }}>
                {moment().format("yyyy-MM-DD") ==
                this.props.dataStart.format("yyyy-MM-DD")
                  ? "Hoje /"
                  : undefined}{" "}
                {this.props.dataStart.format("dddd")}
              </Text>
              
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  value={this.props.dataStart}
                  onChange={(date) => {
                    this.props.setDate(date)
                  }}
                  open={this.state.openDateDialog}
                  onClose={() => {
                    this.setState({openDateDialog: false})
                  }}
                  disablePast={true}
                  renderInput={(params) => {
                    // return <TextField {...params} disabled={true} style={{ color: Colors.tertiary, fontWeight: "bold", textAlign: "center", fontSize: 22 }} />;
                    return (
                      <>
                      <TextInput {...params} style={{display: 'none'}} />
                      <Button onClick={() => {
                        // alert('TESTE')
                        this.setState({openDateDialog: true})
                      }}>
                          <Text {...params} ref={params.inputRef} style={{ color: Colors.tertiary, fontWeight: "bold", textAlign: "center", fontSize: 22 }}>
                            {this.props.dataStart.format('DD/MM/yyyy')}
                          </Text>
                      </Button>
                      </>)
                  }}
                />
              </LocalizationProvider>
            </View>
          )}
          <IconButton
            style={this.styles.fab}
            small
            variant={"contained"}
            icon="arrow-right"
            onClick={() => {
              let dataStart = this.props.dataStart;
              dataStart.add(1, !this.props.month ? "months" : "days");
              this.props.setDate(dataStart);
              /*this.setState({
                  ...this.state, dataStart
                })*/
              this.props.getData(dataStart);
            }}
          >
            <ArrowRight />
          </IconButton>
        </View>
      </View>
    );
  }
  styles = {
    header: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      marginVertical: 15,
    },
    fab: {},

    item: {
      borderLeftWidth: 10,
      borderBottomWidth: 1,
      borderBottomColor: "#e3e3e3",
    },
    notFoundContainer: {
      padding: 5,
      justifyContent: "center",
      margin: 5,
    },
    notFoundText: {
      marginTop: 50,
      textAlign: "center",
    },
  };
}
