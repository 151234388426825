import { BRANDING } from "../../branding";
import { Credentials } from "../models/Credentials";
import {
  postAuth,
  getApi,
  postApi,
  postApiUploadImage,
  postRecovery,
  deleteApi,
  putApi,
  postInternalAuth,
} from "../providers/ApiProvider";
import Storage from "./storage";

const profile_id = BRANDING?.perfilId ?? 4301;//3580; //4301//3580

export const checkServerConnection = async () => {
  return await GetHandler("");
};

const checkStatus = (status, message) => {
  let data = { success: true, message: "", status };
  switch (status) {
    case 3:
      data = {
        ...data,
        success: false,
        message:
          message ?? "Usuário inválido, por favor comunique o administrador.",
      };
      break;
    case 400:
      data = {
        ...data,
        success: false,
        message: message ?? "Houve um problema",
      };
      break;
    case 422:
      data = {
        ...data,
        success: false,
        message: message ?? "Verificação de autenticação inválida",
      };
      break;
    case 401:
      data = {
        ...data,
        success: false,
        message: message ?? "Credênciais Inválidas",
      };
      break;
    case 500:
      data = {
        ...data,
        success: false,
        message: message ?? "Erro interno.",
      };
      break;
  }
  return data;
};
export const LogoutHandler = async () => {
  await Storage.set("auth_token", null);
  await Storage.set("profile_data", null);
};

export const AuthInternalHandler = async (credentials) => {
  let data = {
    login: credentials.email,
    password: credentials.password,
  };

  const auth = await postInternalAuth(data);
  console.log("Internal Login ", auth.data);
  if (auth.status == 200) {
    await Storage.set("auth_token", auth.data);
    return {
      success: true,
    };

    return checkStatus(3);
  }
  return checkStatus(auth.status);
};

export const AuthHandler = async (credentials) => {
  let data = credentials;
  // data.perfil_id = profile_id;
  let token = await Storage.get("auth_token");
  const auth = await postAuth(data, token?.token ?? "");
  console.log("POST AUTH: ", auth.data);
  if (auth) {
    await Storage.set("user_info", auth.data[0]);
    return {
      success: true,
      auth: auth.data[0],
      profile: auth.data[0],
    };
    //await Storage.set('auth_token', auth.data);
    //const profile = await GetHandler('me');
    //console.log('PERFIL ',profile)
    if (profile?.data?.id != null) {
    }
    return checkStatus(3);
  }
  return checkStatus(auth.status);
};

export const RecoveryPwdHandler = async (params) => {
  let data = params;
  data.perfilId = profile_id;

  const recovery = await postRecovery(data);
  if (recovery.status == 200) {
    return {
      success: true,
      data: recovery?.data,
    };
  }

  console.log(recovery);

  return checkStatus(
    recovery.status,
    recovery.data?.message ?? recovery.data.error.message
  );
};

export const GetHandler = async (
  uri,
  params = undefined,
  navigation
) => {
  try {
    let auth_token = await localStorage.getItem("auth_token") == '[object Object]' ? '{}' : localStorage.getItem("auth_token");
    let token = JSON.parse(auth_token);
    const request = await getApi(uri, params, token?.token ?? "");
    if (request.status == 200) {
      return {
        status: request.status,
        success: true,
        data: request.data,
      };
    }
    //console.log('REQ: ', request)
    if (request.status == 401) {
      await Storage.set("auth_token", null);
      await Storage.set("profile_data", null);
      /*if (navigation !== undefined)
                navigation.push('Auth');*/
    }
    return checkStatus(request.status);
  } catch (error) {
    console.error("ERROR : ");
    await Storage.set("auth_token", null);
    await Storage.set("profile_data", null);
    /*  if (navigation !== undefined)
                navigation.push('Auth');    */
    return [];
  }
};

export const PostHandler = async (
  uri,
  params,
  navigation
) => {
  try {
    let token = await Storage.get("auth_token");
    let formData = new FormData();
    const data = Object.entries(params)
      .map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
      .join("&");
    Object.entries(params).map(([key, val]) => {
      /**/
      if (typeof val == "object") {
        
        if (!Array.isArray(val)) {
          //alert(`${key} 2`)
          Object.entries(val).map(([k, v]) => {
            formData.append(`${key}[${k}]`, v);
          });
        } else {
          console.log('ARRAY:: ', val);
          val?.map((vl, k1) => {
            console.log('VAL:: ', k1, vl)
            formData.append(`${key}[${k1}]`, vl);
          });
        }
      } else {
        //alert(`${key} 4`)
        formData.append(key, `${val}`);
      }
    });

    if(params?.foto != undefined){
      console.log('FOTO',params.foto, formData )
      formData.append('foto', params.foto);
    }

    let request = undefined;
    if (params?.uploadFile == true) {
      request = postApiUploadDocument
    } else {
      request = await postApi(uri, formData, token.token);
    }


    if (request.status == undefined) {
      return {
        success: true,
        data: request,
      };
    }
    if (request.status == 200) {
      return {
        success: true,
        data: request.data,
      };
    }

    if (request.status == 400) {
      return {
        success: false,
        data: request.data.error,
      };
    }

    if (request.status == 401) {
      console.log("delete user data and redirect to auth");
      await Storage.set("auth_token", null);
      await Storage.set("profile_data", null);
      /*if (navigation !== undefined)
            navigation.push('Auth');*/
    }
    return checkStatus(request.status);
  } catch (e) {
    console.error("Falha na requisição: ", e);
  }
};

export const putHandler = async (
  uri,
  params,
  navigation
) => {
  let token = await Storage.get("auth_token");
  let formData = new FormData();
  const data = Object.entries(params)
    .map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
    .join("&");
  Object.entries(params).map(([key, val]) => {
    //console.log(`${key} ${typeof val}`)
    if (typeof val == "object") {
      val.map((vl, k1) => {
        Object.entries(vl).map(([k, v]) => {
          formData.append(`${key}[${k1}][${k}]`, v);
        });
      });
    } else {
      formData.append(key, `${val}`);
    }
  });
  const request = await putApi(uri, formData, token.token);
  if (request.status == undefined) {
    return {
      success: true,
      data: request,
    };
  }
  if (request.status == 200) {
    return {
      success: true,
      data: request.data,
    };
  }

  if (request.status == 400) {
    return {
      success: false,
      data: request.data.error,
    };
  }

  if (request.status == 401) {
    console.log("delete user data and redirect to auth");
    await Storage.set("auth_token", null);
    await Storage.set("profile_data", null);
    /*if (navigation !== undefined)
            navigation.push('Auth');*/
  }
  return checkStatus(request.status);
};

export const DeleteHandler = async (
  uri,
  params = undefined,
  navigation
) => {
  try {
    let token = await Storage.get("auth_token");
    const data =
      params !== undefined
        ? Object.entries(params)
          .map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
          .join("&")
        : undefined;
    const request = await deleteApi(uri, data, token.token);
    console.log(uri, request.status);
    console.log(request.data);
    if (request.status == 200) {
      return {
        status: request.status,
        success: true,
        data: request.data,
      };
    }
    //console.log(request)
    return true;
  } catch (error) {
    return [];
  }
};

export const UploadHandler = async (pacientId, params) => {
  let token = await Storage.get("auth_token");
  return await postApiUploadImage(
    `pacientes/${pacientId}/alteraFotoPerfil`,
    params,
    token.token
  );
};

export const BiometryLogin = async (data = null) => {
  let biometryToken = await Storage.get("biometry_token");
  // await Storage.set('biometry_token', null);
  return {
    get: biometryToken,
    set: async (data = null) => {
      await Storage.set("biometry_token", data);
    },
    check: biometryToken == undefined ? false : true,
  };
};

const Handlers = {
  checkServerConnection,
  AuthHandler,
  GetHandler,
  LogoutHandler,
  BiometryLogin,
};
export default Handlers;
