import React, { Component, Fragment } from 'react'
import {View } from 'react-native-web'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
//
import Colors from "../../commons/Colors";
//
import { setSnackbar, setTimer} from '../../actions/appActions'
import { toMoney } from '../../handlers/money';
import { GetHandler } from '../../handlers';
import SearchList from './searchList';
import { withRouter, WithRouterProps } from 'react-router-dom'
import moment from 'moment';


class Favorites extends Component {
  state = {
    profile: undefined,
    data: [],
    doctorData: undefined,
    showOrderpanel: false,
    orderBy: [
      { column: "pontuacao.desc", label: "Avaliação", checked: false },
      {
        column: "totalConsultasAtendidas.desc",
        label: "Número de consultas",
        checked: false,
      },
      { column: "precoConsulta.desc", label: "Maior Preço", checked: false },
      { column: "precoConsulta.asc", label: "Menor Preço", checked: false },
      { column: "pontuacao.desc", label: "Tempo de trabalho", checked: false },
    ],
    results: [],
    pageOptions: undefined,
    refreshing: false,
  };

  async getData() {
    
    const profile = JSON.parse(localStorage.getItem('user_info'));
    try {
      this.setState({...this.state, refreshing: true});
      let request = await GetHandler(
        `pacientes/${profile.id}/favoritos`,
        {perfilId: profile.perfilId ?? ''},
        this.props.navigation
      );

      if (request) {
        console.log('Favorites: ',request?.data?.data)
        this.setState({ results: request?.data?.data ?? [], refreshing: false });
      }else if(request){
        this.setState({...this.state, refreshing: false});
      }
    } catch (e) {
      this.setState({...this.state, refreshing: false});
      console.log(e);
    }
  }

  async componentDidMount() {
    console.log('FILTROS:: ', this.props.filter)
    await this.getData();
  }

    render() {
        return (
            <Fragment>
             <View style={{flexDirection: 'row', justifyContent: 'center'}}>
              <View style={{flexBasis: window.innerWidth <= 680 ? "95%" :'80%'}}>
                <SearchList location={this.props.location.state} data={this.state.results} getData={async () => await this.getData()}  />
              </View>
             </View>
            </Fragment>
        )
    }
}
const mapStateToProps = store => ({
    session: store.authReducer.data,
});
const mapDispatchToProps = dispatch =>
    bindActionCreators({ setSnackbar, setTimer }, dispatch);

    
export default withRouter(Favorites);

const styles = {
  container: {
    paddingTop: 30,
    borderRadius: 0,
    backgroundColor: Colors.primary,
    minHeight: "100%",
  },
  secView: {
    flex: 1,
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    paddingRight: 15,
    maxHeight: 65,
    paddingBottom: 15,
    bottom: 0,
    backgroundColor: Colors.secondary,
    width: "100%",
  },
  logoContainer: {
    alignItems: "center",
    justifyContent: "center",
    padding: 30,
  },
  logo: {
    height: 150,
    width: 150,
    resizeMode: "contain",
  },
  inputDefaultStyle: {
    height: 40,
  },
};
